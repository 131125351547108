import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Box, Card, Divider, Grid, TableContainer } from '@mui/material'

import { AnalystOverviewDialog } from '../../../../../components/AnalystOverviewDialog/AnalystOverviewDialog'
import { GRTable, GRTableColumn, SortOrder } from '../../../../../components/GRTable/GRTable'
import { GRTableColumnSelector } from '../../../../../components/GRTable/GRTableColumnSelector/GRTableColumnSelector'
import { GameSkillThinkingMeterIndicator } from '../../../../../components/GameSkillThingkingMeterIndicator/GameSkillThinkingMeterIndicator'
import { Magnify } from '../../../../../components/Magnify/Magnify'
import { PowerScoreIndicator } from '../../../../../components/PowerScoreIndicator/PowerScoreIndicator'
import { ReviewIndicator } from '../../../../../components/ReviewIndicator/ReviewIndicator'
import { ValueType } from '../../../../../components/ValueIndicator/ValueIndicator'
import { demographicsCellProps, demographicsHeaderCellProps } from '../../../../../features/demographics/components/DemographicsTableBars/DemographicsTableBars'
import { ExportRow, useExportDataMarketExplorerGames } from '../../../../../hooks/exportDataHooks'
import languageService from '../../../../../services/LanguageService'
import { RoleEnum } from '../../../../account'
import { DemographicsColor } from '../../../../demographics/types/DemographicsColor'
import { DemographicsDataKey } from '../../../../demographics/types/DemographicsDataKey'
import { RevenueAndDownloadEstimates } from '../../../../estimates/services/RevenueAndDownloadEstimates'
import { generateExport, generateExportMultipleSheets } from '../../../../export-data/util/workbook'
import { Game } from '../../../../game'
import { GameCardContent } from '../../../../game/components/GameCard/GameCard'
import GameDemographicsTableDataBar from '../../../../game/components/GameDemographicsTableDataBar/GameDemographicsTableDataBar'
import { useCurrentMarket } from '../../../../markets'
import { useGamesOpenForEveryone } from '../../../../settings'
import { useMarketExplorerSegmentFilterGroups } from '../../../hooks/marketExplorerHooks'
import { GamesDataTableColumn, GamesDataTableColumnType } from '../../../types/MarketExplorerGamesDataTableColumns'
import { MarketExplorerSegmentConfiguration } from '../../../types/MarketExplorerSegmentConfiguration'
import { MotivationType } from '../../../types/MotivationType'
import { PlayerArchetype } from '../../../types/PlayerArchetype'
import { ExportSegmentsButton } from '../../ExportSegmentsButton/ExportSegmentsButton'
import { MarketExplorerSegmentCardPopover } from '../../MarketExplorerSegmentCardPopover/MarketExplorerSegmentCardPopover'
import { MarketExplorerSegmentColorBadge } from '../../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import { SegmentQueryDataType } from '../../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerSegmentCard } from '../../MarketExplorerSegments/MarketExplorerSegmentCard/MarketExplorerSegmentCard'
import { MarketExplorerGamesDataTableHeading } from './MarketExplorerGamesDataTableHeading/MarketExplorerGamesDataTableHeading'
import { EmptyRevDLRatioEstimate, MarketExplorerGamesDataTableValue } from './MarketExplorerGamesDataTableValue/MarketExplorerGamesDataTableValue'

export type MarketExplorerGamesDataTableRow = {
  game: Game
  segmentIndex: number
  segmentIndexes?: number[]
  segmentConfiguration: MarketExplorerSegmentConfiguration
  rowId: string
  segmentData: SegmentQueryDataType
  segments?: SegmentQueryDataType[]
}

type MarketExplorerGamesDataTableProps = {
  rows: MarketExplorerGamesDataTableRow[]
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  segmentsConfiguration?: MarketExplorerSegmentConfiguration[]
  tableConfig?: MarketExplorerGamesDataTableConfig
  isLoading?: boolean
  title?: ReactNode
  children?: ReactNode
  showDaysSinceRelease?: boolean
}

type MarketExplorerGamesDataTableConfig = {
  showSegment?: boolean
}

export const MarketExplorerGamesDataTable: FC<MarketExplorerGamesDataTableProps> = ({
  rows = [],
  segmentsConfiguration,
  tableConfig,
  isLoading,
  onSelectedColumnsChange,
  selectedColumns,
  title,
  showDaysSinceRelease,
}) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<Game | null>(null)
  const { currentMarketIso } = useCurrentMarket()

  // NOTE: games open for everyone must be fetched separately, table cells query the data with selectors only for user rights checks
  // subscription to RTK Query hooks causes table to freeze
  useGamesOpenForEveryone()

  const { marketExplorerSegmentMotivationTypes } = useMarketExplorerSegmentFilterGroups()
  const sortAccessor = useCallback((value: any, sortOrder?: SortOrder, hasMarketIso?: boolean, game?: Game) => {
    if (!hasMarketIso || value === undefined || (!hasMarketIso && value === 0)) {
      return Number.MIN_SAFE_INTEGER
    } else {
      return value
    }
  }, [])

  const columnsConfig: GRTableColumn<MarketExplorerGamesDataTableRow, typeof customProps, GamesDataTableColumnType>[] = useMemo(
    () => [
      {
        labelAccessor: ({ customTableProps, columns }) => (
          <GRTableColumnSelector
            columns={columns}
            selectedColumns={customTableProps?.selectedColumns}
            onConfirm={customTableProps?.handleSelectedColumnsChange}
          />
        ),
        headerCellProps: { align: 'left', sx: { pl: 2, minWidth: 400 } },
        sticky: true,
        columns: [
          {
            labelAccessor: t('common:game'),
            accessor: ({ row }) => {
              return (
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                  <Grid item lg={9} sm={9} xs={9}>
                    <GameCardContent game={row.game} variant="table" />
                  </Grid>
                  {row.game.reviewId && row.game.reviewPublished && (
                    <Grid item sx={{ mt: '6px' }} lg={1} sm={1} xs={1}>
                      <ReviewIndicator onClick={() => setAnalystOverviewDialogGame(row.game)} tooltip={t('reviews:tooltip_open_analyst_review')} />
                    </Grid>
                  )}
                  <Grid item lg={2} sm={2} xs={2}>
                    <Grid container alignItems="center" spacing={2} sx={{ display: 'block' }}>
                      {tableConfig?.showSegment &&
                        row.segmentIndexes &&
                        row.segmentIndexes.map((segmentIndex, segmentDataIndex) => {
                          if (!row.segments || !row.segments.length) return null

                          const selectedSegment = row.segments[segmentDataIndex] || row.segments[0]
                          if (!selectedSegment) return null

                          const games = selectedSegment.data ? selectedSegment.data.games : []
                          const complementaryData = selectedSegment.complementaryData

                          return (
                            <Grid item key={segmentIndex}>
                              <MarketExplorerSegmentCardPopover
                                placement="right"
                                content={
                                  <MarketExplorerSegmentCard
                                    segment={selectedSegment.segmentConfiguration}
                                    segmentIndex={segmentIndex}
                                    games={games}
                                    complementaryData={complementaryData}
                                  />
                                }
                              >
                                <Magnify scale={1.25}>
                                  <Box sx={{ cursor: 'pointer' }}>
                                    <MarketExplorerSegmentColorBadge orderNumber={segmentIndex + 1} />
                                  </Box>
                                </Magnify>
                              </MarketExplorerSegmentCardPopover>
                            </Grid>
                          )
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              )
            },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        id: GamesDataTableColumn.FeatureIndicators,
        labelAccessor: t('common:feature_indicators_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.PowerScore />,
            accessor: ({ row }) => <PowerScoreIndicator value={row.game.gpsPerMarket[row.segmentConfiguration.marketIso]} />,
            sortable: true,
            sortAccessor: ({ row }) => row.game.gpsPerMarket[row.segmentConfiguration.marketIso] || 0,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.SkillAndThinking />,
            accessor: ({ row }) => (
              <GameSkillThinkingMeterIndicator
                sensomotoric={row.game.sensomotoric}
                cognitive={row.game.cognitive}
                gps={row.game.gpsPerMarket[row.segmentConfiguration.marketIso]}
              />
            ),
            sortable: true,
            sortAccessor: ({ row, col: { sortOrder } }) =>
              sortAccessor(row.game.sensomotoric, sortOrder, !!row.game.gpsPerMarket[row.segmentConfiguration.marketIso], row.game),
          },
        ],
      },
      {
        id: GamesDataTableColumn.SustainedRanks,
        labelAccessor: t('common:sustained_ranks_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.SustainedGrossingRank />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Rank value={row.game.getSustainedGrossingRankForMarket(row.segmentConfiguration.marketIso)} game={row.game} />
            ),
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.game.getSustainedGrossingRankForMarket(row.segmentConfiguration.marketIso) * -1,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.SustainedDownloadRank />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Rank value={row.game.getSustainedDownloadRankForMarket(row.segmentConfiguration.marketIso)} game={row.game} />
            ),
            sortable: true,
            sortAccessor: ({ row }) => row.game.getSustainedDownloadRankForMarket(row.segmentConfiguration.marketIso) * -1,
          },
        ],
      },
      {
        id: GamesDataTableColumn.DaysSinceRelease,
        labelAccessor: t('common:days_since_release_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.DaysSinceRelease />,
            accessor: ({ row }) => {
              const game = row.game
              const gameVersion = game.versions[currentMarketIso] === undefined ? game.version : game.versions[currentMarketIso]
              return <MarketExplorerGamesDataTableValue.Rank value={row.game.appId} game={game} gameVersion={gameVersion} hasEmptyValue />
            },
            sortable: true,
            sortAccessor: ({ row }) => row.game.getDaysSinceRelease(),
          },
        ],
        hidden: !showDaysSinceRelease,
      },
      {
        id: GamesDataTableColumn.PerformanceMonthly,
        labelAccessor: t('common:monthly_performance_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={30} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).revenue30Day.value}
                type={ValueType.Currency}
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).revenue30Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={30} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).downloads30Day.value}
                type={ValueType.Number}
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).downloads30Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={30} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getRatioEstimatesForRow(row).revenueAndDownloadsRatio30Day.value}
                type={ValueType.Currency}
                maximumFractionDigits={3}
                shorten
                minValue={0}
                displayZero={false}
                emptyValue={<EmptyRevDLRatioEstimate />}
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getRatioEstimatesForRow(row).revenueAndDownloadsRatio30Day.value,
          },
        ],
      },

      {
        id: GamesDataTableColumn.PerformanceHalfYear,
        labelAccessor: t('common:half_year_performance_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={180} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).revenue180Day.value}
                type={ValueType.Currency}
                maximumFractionDigits={2}
                shorten
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).revenue180Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={180} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).downloads180Day.value}
                type={ValueType.Number}
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).downloads180Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={180} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getRatioEstimatesForRow(row).revenueAndDownloadsRatio180Day.value}
                type={ValueType.Currency}
                maximumFractionDigits={3}
                shorten
                minValue={0}
                displayZero={false}
                emptyValue={<EmptyRevDLRatioEstimate />}
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getRatioEstimatesForRow(row).revenueAndDownloadsRatio180Day.value,
          },
        ],
      },
      {
        id: GamesDataTableColumn.PerformanceAnnual,
        labelAccessor: t('common:annual_performance_text'),
        headerCellProps: { sx: { minWidth: 250 } },
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={360} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).revenue360Day.value}
                type={ValueType.Currency}
                maximumFractionDigits={2}
                shorten
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).revenue360Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={360} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getEstimatesForRow(row).downloads360Day.value}
                type={ValueType.Number}
                displayZero
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getEstimatesForRow(row).downloads360Day.value,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={360} />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Estimate
                value={getRatioEstimatesForRow(row).revenueAndDownloadsRatio360Day.value}
                type={ValueType.Currency}
                maximumFractionDigits={3}
                shorten
                minValue={0}
                displayZero={false}
                emptyValue={<EmptyRevDLRatioEstimate />}
                game={row.game}
                marketIso={row.segmentConfiguration.marketIso}
              />
            ),
            sortable: true,
            sortAccessor: ({ row }) => getRatioEstimatesForRow(row).revenueAndDownloadsRatio360Day.value,
          },
        ],
      },

      {
        id: GamesDataTableColumn.Gender,
        labelAccessor: t('common:gender'),
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Male />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Demographics
                value={row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsMale}
                game={row.game}
              >
                <GameDemographicsTableDataBar
                  game={row.game}
                  marketIso={row.segmentConfiguration.marketIso}
                  dataKeys={[DemographicsDataKey.Male, DemographicsDataKey.Female]}
                  colors={[DemographicsColor.Male, DemographicsColor.Female]}
                />
              </MarketExplorerGamesDataTableValue.Demographics>
            ),
            cellProps: demographicsCellProps,
            headerCellProps: demographicsHeaderCellProps,
            sortable: true,
            sortAccessor: ({ row }) => row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsMale || 0,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Female />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Demographics
                value={row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsFemale}
                game={row.game}
              />
            ),
            cellProps: demographicsCellProps,
            headerCellProps: demographicsHeaderCellProps,
            sortable: true,
            sortAccessor: ({ row }) => row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsFemale || 0,
          },
        ],
      },

      {
        id: GamesDataTableColumn.Age,
        labelAccessor: t('common:age'),
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age16To24 />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Demographics
                value={row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge16_24}
                game={row.game}
              >
                <GameDemographicsTableDataBar
                  game={row.game}
                  marketIso={row.segmentConfiguration.marketIso}
                  dataKeys={[DemographicsDataKey.Age16_24, DemographicsDataKey.Age25_44, DemographicsDataKey.Age45_plus]}
                  colors={[DemographicsColor.Age16_24, DemographicsColor.Age25_44, DemographicsColor.Age45_plus]}
                />
              </MarketExplorerGamesDataTableValue.Demographics>
            ),
            cellProps: demographicsCellProps,
            headerCellProps: demographicsHeaderCellProps,
            sortable: true,
            sortAccessor: ({ row }) => row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge16_24 || 0,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age25To44 />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Demographics
                value={row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge25_44}
                game={row.game}
              />
            ),
            cellProps: demographicsCellProps,
            headerCellProps: demographicsHeaderCellProps,
            sortable: true,
            sortAccessor: ({ row }) => row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge25_44 || 0,
          },
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age45Plus />,
            accessor: ({ row }) => (
              <MarketExplorerGamesDataTableValue.Demographics
                value={row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge45}
                game={row.game}
              />
            ),
            cellProps: demographicsCellProps,
            headerCellProps: demographicsHeaderCellProps,
            sortable: true,
            sortAccessor: ({ row }) => row.game.demographics[row.segmentConfiguration.marketIso]?.demographicsAge45 || 0,
          },
        ],
      },
      ...Object.values(PlayerArchetype).map((playerArchetype) => {
        return {
          id: playerArchetype,
          labelAccessor: t('segment:player_archetypes'),
          columnSelectorLabelAccessor: () => languageService.getTranslation('motivations', playerArchetype),
          columns: [
            {
              labelAccessor: () => <MarketExplorerGamesDataTableHeading.Archetype archetype={playerArchetype} />,
              accessor: ({ row }: { row: MarketExplorerGamesDataTableRow }) => {
                return (
                  <MarketExplorerGamesDataTableValue.Archetype
                    value={
                      row.segmentData?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === row.game.id)?.motivationSegmentValues?.[
                        playerArchetype
                      ] || 0
                    }
                    game={row.game}
                  />
                )
              },
              headerCellProps: { sx: { minWidth: 75 } },
              sortable: true,
              sortAccessor: ({ row }: { row: MarketExplorerGamesDataTableRow }) =>
                row.segmentData?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === row.game.id)?.motivationSegmentValues?.[
                  playerArchetype
                ] || 0,
            },
          ],
        }
      }),
      ...marketExplorerSegmentMotivationTypes.map((motivationGroup) => {
        return {
          labelAccessor: motivationGroup.label,
          id: motivationGroup.types.reduce((acc, type) => type.id, null as unknown as MotivationType) as MotivationType,
          columns: motivationGroup.types.map((motivation) => {
            return {
              labelAccessor: () => <MarketExplorerGamesDataTableHeading.Motivation motivationType={motivation.type} groupLabel={motivationGroup.label} />,
              accessor: ({ row }: { row: MarketExplorerGamesDataTableRow }) => {
                const motivationValue =
                  row.segmentData?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === row.game.id)?.motivationValues?.[
                    motivation.type
                  ] || 0

                return <MarketExplorerGamesDataTableValue.Motivation value={motivationValue} game={row.game} />
              },
              headerCellProps: { sx: { minWidth: 150 } },
              sortable: true,
              sortAccessor: ({ row }: { row: MarketExplorerGamesDataTableRow }) =>
                row.segmentData?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === row.game.id)?.motivationValues?.[
                  motivation.type
                ] || 0,
            }
          }),
        } as GRTableColumn<MarketExplorerGamesDataTableRow, typeof customProps, GamesDataTableColumnType>
      }),
    ],
    [currentMarketIso, marketExplorerSegmentMotivationTypes, showDaysSinceRelease, sortAccessor, t, tableConfig?.showSegment]
  )

  const [columns, setColumns] = useState<GRTableColumn<MarketExplorerGamesDataTableRow, typeof customProps, GamesDataTableColumnType>[]>(columnsConfig)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<MarketExplorerGamesDataTableRow, typeof customProps, GamesDataTableColumnType>[]) => {
    setColumns(updatedColumns)
  }, [])

  const handleSelectedColumnsChange = useCallback(
    (columnIds: GamesDataTableColumnType[]) => {
      onSelectedColumnsChange && onSelectedColumnsChange(columnIds)
    },
    [onSelectedColumnsChange]
  )

  const customProps = useMemo(() => {
    return { selectedColumns, handleSelectedColumnsChange }
  }, [handleSelectedColumnsChange, selectedColumns])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const [segmentConfigurations, setSegmentConfigurations] = useState<MarketExplorerSegmentConfiguration[]>([])
  const [singleSegmentIndex, setSingleSegmentIndex] = useState<number | undefined>(undefined)
  const { segmentExportRows } = useExportDataMarketExplorerGames(segmentConfigurations, rows, singleSegmentIndex, isExporting)

  useEffect(() => {
    if (!isExporting || !segmentExportRows.length) return
    setIsExporting(false)
    switch (exportFormat) {
      case 'csv': {
        segmentExportRows.forEach((exportRows: ExportRow[], index: number) => {
          const sheetId =
            segmentConfigurations.length === 1
              ? `market-explorer-${singleSegmentIndex !== undefined ? singleSegmentIndex + 1 : ''}-${segmentConfigurations[index].marketIso}-games`
              : `market-explorer-${index + 1}-${segmentConfigurations[index].marketIso}-games`

          generateExport(exportFormat, exportRows, sheetId, sheetId)
        })
        break
      }
      case 'xlsx': {
        let sheetNames: string[] = []

        segmentConfigurations.forEach((segmentConfiguration, index) => {
          const sheetId =
            segmentConfigurations.length === 1
              ? `market-explorer-${singleSegmentIndex !== undefined ? singleSegmentIndex + 1 : ''}-${segmentConfiguration.marketIso}-games`
              : `market-explorer-${index + 1}-${segmentConfiguration.marketIso}-games`
          sheetNames.push(sheetId)
        })

        const allSegmentsSheetId = segmentConfigurations.length === 1 ? sheetNames[0] : 'market-explorer-allsegments-games'
        generateExportMultipleSheets(exportFormat, segmentExportRows, sheetNames, allSegmentsSheetId)
        break
      }
    }
  }, [exportFormat, isExporting, segmentConfigurations, segmentExportRows, singleSegmentIndex, t])

  const handleDataExport = (format: BookType, segmentConfigurations: MarketExplorerSegmentConfiguration[], singleSegmentIndex?: number) => {
    setExportFormat(format)
    setSegmentConfigurations(segmentConfigurations)
    setIsExporting(true)
    singleSegmentIndex !== undefined ? setSingleSegmentIndex(singleSegmentIndex) : setSingleSegmentIndex(undefined)
  }

  return (
    <Card>
      {(title || segmentsConfiguration) && (
        <Grid container alignItems="center" p={2}>
          {title && <Grid item>{title}</Grid>}
          {segmentsConfiguration && (
            <Grid item sx={{ ml: 'auto' }}>
              <ExportSegmentsButton
                segments={segmentsConfiguration}
                onExport={handleDataExport}
                accessRoles={[RoleEnum.csv_market_explorer_games, RoleEnum.csv_export_games]}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Divider light />
      <TableContainer ref={containerRef}>
        <GRTable
          rows={rows}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="rowId"
          noRowsLabel={t('market-explorer:no_games_available')}
          isLoading={isLoading}
          scroller={containerRef}
          customProps={customProps}
          selectedColumns={selectedColumns}
        />
      </TableContainer>
      <AnalystOverviewDialog
        reviewId={analystOverviewDialogGame?.reviewId}
        gameName={analystOverviewDialogGame?.resolvedName}
        open={!!analystOverviewDialogGame}
        onClose={() => setAnalystOverviewDialogGame(null)}
      />
    </Card>
  )
}

const getEstimatesForRow = (row: MarketExplorerGamesDataTableRow) => new RevenueAndDownloadEstimates(row.game, row.segmentConfiguration.marketIso)
const getRatioEstimatesForRow = (row: MarketExplorerGamesDataTableRow) => new RevenueAndDownloadEstimates(row.game, row.segmentConfiguration.marketIso, 0, 0)
