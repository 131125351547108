import { FC, ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CheckIcon from '@mui/icons-material/Check'
import { List, Paper, Typography, Divider, Box, ListItemButton, Chip, Grid } from '@mui/material'

import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { endOfDayTimestamp } from '../../../../../helpers/endOfDayTimestamp'
import languageService from '../../../../../services/LanguageService'
import { useCurrentMarket } from '../../../../markets'
import { useLiveEventTagGroupsMapByEventTypeId } from '../../../hooks/useLiveEventTagGroups'
import { useTrackedEvents } from '../../../hooks/useTrackedEvents'
import { LiveEventTagGroup } from '../../../types/LiveEventTagGroup'
import { LiveEventTrackerSearchMethod } from '../../../types/LiveEventTrackerSearchMethod'
import { TrackedGame } from '../../../types/TrackedGame'
import { TrackingEvent } from '../../../types/TrackingEvents'

/**
 * Component representing event dialog All events tab
 */
type AllEventsTabProps = {
  currentTrackedGame?: TrackedGame
  currentEventTypeId?: string
  currentEventId?: string
  onEventSelect: (event: TrackingEvent) => void
  children?: ReactNode
}

export const AllEventsTab: FC<AllEventsTabProps> = ({ currentTrackedGame, onEventSelect, currentEventTypeId, currentEventId, children }) => {
  const { currentMarketIso } = useCurrentMarket()
  const gameIds = useMemo(() => (currentTrackedGame ? [currentTrackedGame?.game.id] : []), [currentTrackedGame])
  const trackedEventsQuery = useTrackedEvents({
    searchMethod: LiveEventTrackerSearchMethod.games,
    gameIds: gameIds,
    startTimestamp: 0,
    endTimestamp: endOfDayTimestamp(),
    marketIso: currentMarketIso,
  })
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  // group events by event type
  const eventsGroupedByType = useMemo(() => {
    return Object.entries(trackedEventsQuery.data?.events || {}).reduce((acc, [gameId, events]) => {
      const uniqueEvents = events
        .filter((event) => {
          return (
            liveEventTagGroupsMapByEventTypeId[event.typeId] && liveEventTagGroupsMapByEventTypeId[event.typeId].id !== LiveEventTagGroup.DEFINING_OCCURRENCES
          )
        })
        .reduce((accEvents, event) => {
          accEvents[event.eventId] = event
          return accEvents
        }, {} as { [eventId: string]: TrackingEvent })

      Object.values(uniqueEvents).forEach((event) => {
        acc[event.typeId] = acc[event.typeId] ? [...acc[event.typeId], event] : [event]
      })

      return acc
    }, {} as { [eventTypeId: string]: TrackingEvent[] })
  }, [trackedEventsQuery, liveEventTagGroupsMapByEventTypeId])

  // extract events of selected event type and the rest
  const selectedEventTypeEvents = currentEventTypeId ? eventsGroupedByType[currentEventTypeId] || [] : []
  const { [currentEventTypeId as string]: excludedEventType, ...otherEventTypeEvents } = eventsGroupedByType

  // sort events by name
  selectedEventTypeEvents.sort((event1, event2) => event1.name.localeCompare(event2.name))
  Object.entries(otherEventTypeEvents).reduce((acc, [eventTypeId, events]) => {
    acc[eventTypeId] = events.sort((event1, event2) => event1.name.localeCompare(event2.name))

    return acc
  }, {} as { [eventTypeId: string]: TrackingEvent[] })

  const isLoading = trackedEventsQuery.isLoading || trackedEventsQuery.isFetching

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <EventTypeSection events={selectedEventTypeEvents} eventTypeId={currentEventTypeId} currentEventId={currentEventId} onItemClick={onEventSelect} />
      {Object.keys(otherEventTypeEvents).length > 0 && (
        <>
          <Divider sx={{ mt: 4, mb: 2 }}>
            <Trans i18nKey="live-events:event_dialog_all_events_other" />
          </Divider>
          {Object.entries(otherEventTypeEvents)
            .sort((entry1, entry2) => languageService.getTranslation('tags', entry1[0]).localeCompare(languageService.getTranslation('tags', entry2[0])))
            .map(([eventTypeId, events]) => {
              return <EventTypeSection events={events} eventTypeId={eventTypeId} onItemClick={onEventSelect} key={eventTypeId} />
            })}
        </>
      )}
    </>
  )
}

type EventTypeSectionProps = {
  events: TrackingEvent[]
  eventTypeId?: string
  currentEventId?: string
  onItemClick: (event: TrackingEvent) => void
}

const EventTypeSection: FC<EventTypeSectionProps> = ({ events, eventTypeId, currentEventId, onItemClick }) => {
  const { t } = useTranslation()

  return (
    <>
      {eventTypeId && (
        <Typography variant="h3" sx={{ mb: 2 }}>
          {languageService.getTranslation('tags', eventTypeId)}
        </Typography>
      )}
      <List component={Paper} disablePadding sx={{ mb: 3 }}>
        {events.map((event, index, list) => {
          return (
            <Box key={event.eventId}>
              <ListItemButton onClick={() => onItemClick(event)}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body1">{event.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" gap={2}>
                      {currentEventId === event.eventId && <CheckIcon color="success" />}
                      {event.active === false && <Chip label={t('live-events:event_inactive')} color="warning" size="small" sx={{ m: 0 }} />}
                    </Grid>
                  </Grid>
                </Grid>
              </ListItemButton>

              {index < list.length - 1 && <Divider />}
            </Box>
          )
        })}
      </List>
    </>
  )
}
