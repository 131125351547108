import { startOfDay } from 'date-fns'
import numbro from 'numbro'
import { Unit } from 'react-calendar-timeline'

import { startOfNextDay } from '../../../helpers/date'
import { limitValue } from '../../../helpers/limitValue'
import { pricePointCurrencySymbol } from '../../../internal/features/live-events-tracking/components/PricePointContainer/PricePointSelectMenu/PricePointSelectMenu'
import { PricePoint } from '../../../internal/features/live-events-tracking/types/PricePoint'
import { Currency, getUnitPrice } from '../../../internal/types/Currency'
import { durationByDurationCategory } from '../const/const'
import { LiveEventCalendarAdditionalData, LiveEventCalendarAdditionalDataId } from '../types/LiveEventAdditionalCalendarData'
import { LiveEventDuration } from '../types/LiveEventDuration'
import { LiveEventPricePoint } from '../types/LiveEventPricePoint'
import { EventTypeStat, IntervalsByDurationCategory, LiveEventTypeDurationCategory } from '../types/LiveEventStatistics'
import { LiveEventTrackerTab } from '../types/LiveEventTrackerTab'
import { TrackingEvent } from '../types/TrackingEvents'

export const resolveTrackingEventMaxTime = (events: TrackingEvent[], initialValue: number = 0) => {
  return events.reduce((acc, event) => {
    return event.end > acc ? event.end : acc
  }, initialValue)
}

export const getLiveEventDurations = () => {
  return [
    new LiveEventDuration('Duration1Day', '1', 1),
    new LiveEventDuration('Duration2Days', '2', 2),
    new LiveEventDuration('Duration3Days', '3', 3),
    new LiveEventDuration('Duration4Days', '4', 4),
    new LiveEventDuration('Duration5Days', '5', 5),
    new LiveEventDuration('Duration6Days', '6', 6),
    new LiveEventDuration('Duration7Days', '7', 7),
    new LiveEventDuration('Duration8-13Days', '8-13', 8, 13),
    new LiveEventDuration('Duration14Days', '14', 14),
    new LiveEventDuration('Duration15-27Days', '15-27', 15, 27),
    new LiveEventDuration('Duration28-31Days', '28-31', 28, 31),
    new LiveEventDuration('Duration32PlusDays', '32+', 32, Number.MAX_SAFE_INTEGER),
  ]
}

export const getLiveEventCalendarAdditionalDatas = () => {
  return [
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.GameVersion, 'live-events:game_versions_timeline_group'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.TopIAPs, 'live-events:top_iaps_calendar_group_name'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.RevenueChange, 'live-events:performance_effect_type_revenue'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.DownloadChange, 'live-events:performance_effect_type_download'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.AUChange, 'live-events:performance_effect_type_au'),
    new LiveEventCalendarAdditionalData(
      LiveEventCalendarAdditionalDataId.AverageRevenuePerDailyActiveUser,
      'live-events:average_revenue_per_daily_active_user'
    ),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.AnalystReview, 'live-events:analyst_review_timeline_group'),
  ]
}

export const getLiveEventPricePoints = () => {
  return [
    new LiveEventPricePoint('PricePointUnder5', '<' + pricePointCurrencySymbol + '5', 0, 5),
    new LiveEventPricePoint('PricePoint5-10', pricePointCurrencySymbol + '5 - ' + pricePointCurrencySymbol + '10', 5, 10),
    new LiveEventPricePoint('PricePoint10-20', pricePointCurrencySymbol + '10 - ' + pricePointCurrencySymbol + '20', 10, 20),
    new LiveEventPricePoint('PricePoint20-30', pricePointCurrencySymbol + '20 - ' + pricePointCurrencySymbol + '30', 20, 30),
    new LiveEventPricePoint('PricePoint30-40', pricePointCurrencySymbol + '30 - ' + pricePointCurrencySymbol + '40', 30, 40),
    new LiveEventPricePoint('PricePoint40-50', pricePointCurrencySymbol + '40 - ' + pricePointCurrencySymbol + '50', 40, 50),
    new LiveEventPricePoint('PricePointOver50', pricePointCurrencySymbol + '50+', 50, Number.MAX_SAFE_INTEGER),
  ]
}

export const getSelectedPricePointMinMax = (selectedPricePoints: string[], pricePoints: LiveEventPricePoint[], type: 'min' | 'max') => {
  const selectedPricePointValues = pricePoints
    .filter((pricePoint) => selectedPricePoints.includes(pricePoint.id))
    .map((pricePoint) => (type === 'min' ? pricePoint.start : pricePoint.end))
  return selectedPricePointValues.length > 0 ? Math[type](...selectedPricePointValues) : undefined
}

/**
 * Get change values (absolute change, percent change) for Event Type Stats duration values
 */
export const getEventStatDurationChange = (stat: EventTypeStat, referenceStat: EventTypeStat | undefined, durationField: keyof EventTypeStat['duration']) => {
  const referenceDurationStart = +(referenceStat?.duration[durationField].value.start || 0)
  const referenceDurationEnd = +(referenceStat?.duration[durationField].value.end || 0)
  const changeValue = +stat.duration[durationField].value.end - +stat.duration[durationField].value.start - (referenceDurationEnd - referenceDurationStart)
  const percentChange = parseFloat((changeValue / (referenceDurationEnd - referenceDurationStart)).toFixed(2))

  return [changeValue, percentChange]
}

/**
 * Categorizes the given event by its duration to given duration categories
 */
export const convertTrackingEventToDurationCategory = (event: TrackingEvent, categories: IntervalsByDurationCategory = durationByDurationCategory) => {
  const eventLength = startOfNextDay(event.end) - startOfDay(event.start).getTime()
  let resolvedCategory: LiveEventTypeDurationCategory = LiveEventTypeDurationCategory.Days0To1
  Object.entries(categories).every(([category, interval]) => {
    if (eventLength >= +interval.start && eventLength <= +interval.end) {
      resolvedCategory = category as LiveEventTypeDurationCategory
      return false
    }

    return true
  })

  return resolvedCategory
}

export const getPreviousCalendarHeaderUnit = (unit: Unit) => {
  return getCalendarUnitByOffset(unit, -1)
}

export const getNextCalendarHeaderUnit = (unit: Unit) => {
  return getCalendarUnitByOffset(unit, 1)
}

const getCalendarUnitByOffset = (unit: Unit, offset: number) => {
  const units: Unit[] = ['second', 'minute', 'hour', 'day', 'month', 'year']
  const currentUnitIndex = units.findIndex((u) => u === unit)
  const newUnitIndex = limitValue(currentUnitIndex + offset, 0, units.length - 1)

  return units[newUnitIndex]
}

/**
 * Get price point data converted to unit values
 */
export const getPricePointUnitValue = (pricePoint: PricePoint, currencies: Currency[]) => {
  let currency = pricePoint.currencyId === '' ? null : currencies.find((currency) => currency.id === pricePoint.currencyId)
  if (currency) {
    const unitPrice = getUnitPrice(currency)
    return { currencyName: currency.name, value: parseFloat(numbro(pricePoint.value * unitPrice).format({ mantissa: 2 })) }
  }
  return undefined
}

/**
 * Get truncated text
 */
export const truncateText = (text: String, lenght: number) => {
  if (text.length <= lenght) return text

  const subString = text.slice(0, lenght - 1)
  const lastSpaceIndex = subString.lastIndexOf(' ')

  const truncatedText = lastSpaceIndex > 0 ? subString.slice(0, lastSpaceIndex) : subString
  return truncatedText + '...'
}

/**
 * Resolve additional data item disabled state based on selected subpage and dataId
 */
export const resolveAdditionalDataDisabledState = (subpage: LiveEventTrackerTab, dataId: LiveEventCalendarAdditionalDataId) => {
  return (
    [LiveEventTrackerTab.Events, LiveEventTrackerTab.Statistics].includes(subpage) ||
    (subpage === LiveEventTrackerTab.Feed && dataId !== LiveEventCalendarAdditionalDataId.AnalystReview)
  )
}
/*
const helpLinksByPerformanceEffectType: { [key in PerformanceEffectType]?: string } = {
  [PerformanceEffectType.DAU]: 'https://docs.gamerefinery.com/en/articles/9113643-active-user-dau-mau-metrics-methodology',
  [PerformanceEffectType.MAU]: 'https://docs.gamerefinery.com/en/articles/9113643-active-user-dau-mau-metrics-methodology',
}
export const getHelpLinkByPerformanceEffectType = (dataId: PerformanceEffectType) => {
  return helpLinksByPerformanceEffectType[dataId]
}
*/
