import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Save } from '@mui/icons-material'
import { Card, CardContent, FormControl, TextField, InputLabel, Select, MenuItem, Divider, Button, CircularProgress, SelectChangeEvent } from '@mui/material'

import { useGetUserProfileQuery, useUpdateUserLanguageMutation, useUpdateUserProfileMutation } from '../../api/combined'
import { JobFunction } from '../../features/account/types/JobFunction'
import { UserLanguage } from '../../features/account/types/User'
import { UserProfile } from '../../features/account/types/UserProfile'
import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import languageService from '../../services/LanguageService'
import PageService from '../../services/PageService'
import utilsService from '../../services/UtilsService'
import './MyProfilePage.scss'

const MyProfilePage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePage(PageService.getPageWithId('my-profile'), 'Visited My Profile')
  useDocumentTitle(t('profile:my_profile'))

  // TODO-?-KALLE: refactor data loading to a parent component to provide initial data correctly
  const { data: currentUser } = useGetUserProfileQuery()

  const [userFormData, setUserFormData] = useState<UserProfile>({
    currentPassword: null,
    email: currentUser?.email || '',
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    title: currentUser?.title,
    language: currentUser?.language,
  })
  const [selectedLanguage, setSelectedLanguage] = useState<UserLanguage>(currentUser?.language || 'en')
  const [selectedJobFunction, setSelectedJobFunction] = useState<JobFunction | undefined>(currentUser?.jobFunction || undefined)
  const [savingUser, setSavingUser] = useState(false)
  const [errorSavingUser, setErrorSavingUser] = useState(false)
  const [updateLanguage] = useUpdateUserLanguageMutation()
  const [updateUserProfile, { isSuccess: isUpdateUserProfileSuccess, isError: isUpdateUserProfileError }] = useUpdateUserProfileMutation()

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const value = event.target.value as UserLanguage
    setSelectedLanguage(value)
    updateLanguage(value)
    window.location.reload()
  }

  const handleJobFunctionChange = (event: SelectChangeEvent) => {
    const value = event.target.value as JobFunction
    setSelectedJobFunction(value)
  }

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setUserFormData((previousState) => {
      previousState[event.target.name as keyof Omit<UserProfile, 'jobFunction' | 'language'>] = event.target.value
      return { ...previousState }
    })
  }

  const handleSave = useCallback(
    (event: any) => {
      event.preventDefault()
      if (savingUser) {
        return
      }

      setSavingUser(true)
      setErrorSavingUser(false)
      updateUserProfile({
        profile: { ...userFormData, language: selectedLanguage, jobFunction: selectedJobFunction },
        params: { requireCurrentPassword: true },
      })
    },
    [selectedLanguage, selectedJobFunction, userFormData, savingUser, updateUserProfile]
  )

  useEffect(() => {
    if (isUpdateUserProfileSuccess) {
      setSavingUser(false)
      dispatch(displaySnackBar({ message: t('profile:profile_saved'), severity: 'success', open: true }))
      window.location.reload()
    }
  }, [isUpdateUserProfileSuccess, dispatch, t])

  useEffect(() => {
    if (isUpdateUserProfileError) {
      setSavingUser(false)
      setErrorSavingUser(true)
      dispatch(displaySnackBar({ message: t('profile:error_make_sure_you_entered_correct_password'), severity: 'error', open: true }))
    }
  }, [isUpdateUserProfileError, dispatch, t])

  const languagesHtml = languageService.getLanguagesArray().map((language) => {
    return (
      <MenuItem key={language.id} value={language.id}>
        {language.name}
      </MenuItem>
    )
  })

  const jobFunctionsHtml = Object.values(JobFunction).map((jobFunction) => {
    return (
      <MenuItem key={jobFunction} value={jobFunction}>
        {t(`job-functions:${jobFunction}`)}
      </MenuItem>
    )
  })

  const validateEmail = (input: UserProfile) => {
    if (!input.email || !input.email.length) return t('organization:email_is_required')
    return input.email.length && !utilsService.validateEmail(input.email) ? t('organization:invalid_email') : null
  }

  return (
    <div className="MyProfilePage">
      <p dangerouslySetInnerHTML={{ __html: t('profile:info') }}></p>

      <Card>
        <CardContent>
          <form onSubmit={handleSave}>
            <FormControl size="small" variant="outlined" fullWidth={true} className="input-with-bottom-margin">
              <InputLabel htmlFor="language-selector-label">{t('common:language')}</InputLabel>
              <Select
                id="language-selector"
                labelId="language-selector-label"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                label={t('common:language')}
              >
                {languagesHtml}
              </Select>
            </FormControl>

            <Divider />

            <TextField
              name="email"
              type="text"
              label={t('common:user_email')}
              defaultValue={userFormData.email}
              onChange={handleInput}
              className="input-with-bottom-margin"
              variant="outlined"
              size="small"
              fullWidth={true}
              required={!userFormData.email?.length}
              error={!!validateEmail(userFormData)}
              helperText={validateEmail(userFormData)}
            ></TextField>

            <TextField
              name="firstName"
              type="text"
              label={t('common:user_firstname')}
              defaultValue={userFormData.firstName}
              onChange={handleInput}
              className="input-with-bottom-margin"
              variant="outlined"
              size="small"
              fullWidth={true}
              required={!userFormData.firstName?.length}
              error={!userFormData.firstName?.length}
              helperText={!userFormData.firstName?.length && t('organization:firstname_is_required')}
            ></TextField>

            <TextField
              name="lastName"
              type="text"
              label={t('common:user_lastname')}
              defaultValue={userFormData.lastName}
              onChange={handleInput}
              className="input-with-bottom-margin"
              variant="outlined"
              size="small"
              fullWidth={true}
              required={!userFormData.lastName?.length}
              error={!userFormData.lastName?.length}
              helperText={!userFormData.lastName?.length && t('organization:lastname_is_required')}
            ></TextField>

            <TextField
              name="title"
              type="text"
              label={t('common:user_title')}
              defaultValue={userFormData.title}
              onChange={handleInput}
              className="input-with-bottom-margin"
              variant="outlined"
              size="small"
              fullWidth={true}
            ></TextField>

            <FormControl size="small" variant="outlined" fullWidth={true} className="input-with-bottom-margin">
              <InputLabel htmlFor="job-function-selector-label">{t('common:user_job_function')}</InputLabel>
              <Select
                id="job-function-selector"
                labelId="language-selector-label"
                value={selectedJobFunction}
                onChange={handleJobFunctionChange}
                label={t('common:user_job_function')}
              >
                {jobFunctionsHtml}
              </Select>
            </FormControl>

            <TextField
              name="currentPassword"
              type="password"
              label={t('profile:type_current_password')}
              defaultValue={userFormData.currentPassword}
              onChange={handleInput}
              error={errorSavingUser}
              className="input-with-bottom-margin"
              variant="outlined"
              size="small"
              fullWidth={true}
            ></TextField>

            <Divider />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<Save />}
              disabled={!userFormData.currentPassword || !userFormData.email || !userFormData.firstName || !userFormData.lastName || savingUser}
            >
              {t('common:save')}
            </Button>

            {savingUser && <CircularProgress size={30} style={{ verticalAlign: 'middle', marginLeft: '15px' }}></CircularProgress>}
          </form>
        </CardContent>
      </Card>
    </div>
  )
}

export default MyProfilePage
