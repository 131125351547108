import { t } from 'i18next'
import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useUpdateUserSettingMutation } from '../../../../../api/combined'
import { UserSettingKeys } from '../../../../../features/account/types/UserSettings'
import { displaySnackBar } from '../../../../../features/snackbar'
import { useAppDispatch } from '../../../../../hooks/storeHooks'
import { translationLanguagesToShow } from '../../../../components/InputTranslator/InputTranslator'
import { InputTranslatorDialog } from '../../../../components/InputTranslator/InputTranslatorDialog/InputTranslatorDialog'
import { InputTranslatorType } from '../../../../types/InputTranslatorType'

export type AnalystNoteDialogData = {
  eventId: string
  eventNote: string | undefined
  eventNotes:
    | {
        [eventId: string]: string
      }
    | undefined
}

type AnalystEventNoteDialogProps = {
  data: AnalystNoteDialogData | undefined
  open: boolean
  onClose: () => void
}

type FormInputs = {
  content: {
    en?: string | undefined
    ja?: string | undefined
    zh?: string | undefined
  }
}
export const AnalystEventNoteDialog: FC<AnalystEventNoteDialogProps> = ({ data, open, onClose }) => {
  const [saveUserSetting] = useUpdateUserSettingMutation()
  const dispatch = useAppDispatch()
  const defaultNoteValues = useMemo(() => ({ content: { en: data?.eventNote, ja: undefined, zh: undefined } }), [data?.eventNote])
  const { control, getValues, reset } = useForm<FormInputs>({ defaultValues: defaultNoteValues })
  useEffect(() => {
    reset(defaultNoteValues)
  }, [defaultNoteValues, reset])

  const handleClose = () => {
    if (data?.eventId) {
      const noteText = getValues('content.en')
      const eventNotesToSave = data.eventNotes
        ? noteText?.length === 0
          ? Object.fromEntries(Object.entries(data.eventNotes).filter(([key]) => key !== data.eventId))
          : { ...data.eventNotes, [data.eventId]: noteText }
        : { [data.eventId]: noteText }
      saveUserSetting({ settingKey: UserSettingKeys.liveeventsAnalystNotes, value: { ...eventNotesToSave } })
      reset(defaultNoteValues)
      onClose()
      return
    }
    dispatch(displaySnackBar({ message: t('internal-live-events:event_note_error'), severity: 'error', open: true }))
    onClose()
  }

  return (
    <>
      <Controller
        name="content"
        control={control}
        render={({ field }) => {
          return (
            <InputTranslatorDialog
              defaultValues={field.value}
              onChange={field.onChange}
              open={open}
              onClose={handleClose}
              title={t('internal-live-events:event_note')}
              inputType={InputTranslatorType.WYSIWYG}
              selectedLanguages={translationLanguagesToShow}
            />
          )
        }}
      />
    </>
  )
}
