import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { FormControlLabel, Grid, SelectChangeEvent, Switch, TableContainer } from '@mui/material'
import { Box } from '@mui/system'

import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { TrendIndicator, TrendIndicatorType } from '../../../../components/TrendIndicator/TrendIndicator'
import utilsService from '../../../../services/UtilsService'
import { useMarketShareAceessCheck } from '../../../account/hooks/roleHooks'
import QuarterSelector from '../../../quarter/components/QuarterSelector'
import quarterService, { QuarterDataKey } from '../../../quarter/services/QuarterService'
import { MarketShareQuarter } from '../../../quarter/types/MarketShareQuarter'
import { Quarter } from '../../../quarter/types/Quarter'
import { MarketShare } from '../../types/MarketShare'
import { MarketShareNumberType, MarketShareNumberTypeId } from '../../types/MarketShareNumberType'
import { MarketShareScopeType } from '../../types/MarketShareScopeType'
import MarketShareTopGameCard from '../MarketShareTopGameCard/MarketShareTopGameCard'

interface Props {
  calculatedQuarters: Quarter[]
  marketShares: MarketShare[]
  marketShareScopeType: MarketShareScopeType
  marketShareNumberType: MarketShareNumberType
  selectedQuarter: Quarter
  onQuarterChangeCallback: (quarterIdentifier: string) => void
  onMarketShareLinkClickCallback: (marketShareName: string) => void
}

const MarketShareQuarterOverview: React.FC<Props> = ({
  calculatedQuarters,
  marketShares,
  marketShareScopeType,
  marketShareNumberType,
  selectedQuarter,
  onQuarterChangeCallback,
  onMarketShareLinkClickCallback,
}) => {
  const { t } = useTranslation()
  const [showScopeTypeShare, setShowScopeTypeShare] = useState(false)
  const hasAccessToMarketShare = useMarketShareAceessCheck()

  const handleQuarterChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as string
      onQuarterChangeCallback(value)
    },
    [onQuarterChangeCallback]
  )

  const getMarketShareQuarterTop3Games = useCallback((marketShareQuarter: MarketShareQuarter, selectedQuarter: Quarter) => {
    let top3Games = marketShareQuarter.games
      .filter((game) => game.marketLevelQuarters[selectedQuarter.quarterIdentifier].revenueTop500Value > 0)
      .sort((a, b) =>
        a.marketLevelQuarters[selectedQuarter.quarterIdentifier].revenueTop500Value <
        b.marketLevelQuarters[selectedQuarter.quarterIdentifier].revenueTop500Value
          ? 1
          : -1
      )

    if (top3Games.length !== 0 && top3Games.length > 3) {
      top3Games.length = 3
    }

    return top3Games
  }, [])

  const containerRef = useRef(null)

  const customProps = useMemo(() => {
    return {
      marketShareScopeType,
      marketShareNumberType,
      selectedQuarter,
      showScopeTypeShare,
      setShowScopeTypeShare,
      handleQuarterChange,
      onMarketShareLinkClickCallback,
    }
  }, [
    marketShareScopeType,
    marketShareNumberType,
    selectedQuarter,
    showScopeTypeShare,
    setShowScopeTypeShare,
    handleQuarterChange,
    onMarketShareLinkClickCallback,
  ])

  const tableColumns: GRTableColumn<MarketShare, typeof customProps>[] = [
    {
      columns: [
        {
          labelAccessor: ({ customTableProps }) => (
            <>{customTableProps && customTableProps.marketShareScopeType ? t(customTableProps.marketShareScopeType.name) : ''}</>
          ),
          accessor: ({ row: marketShare, rowIndex, customTableProps }) => (
            <Link
              className={hasAccessToMarketShare ? 'MarketSharePage__MarketShareLink' : 'MarketSharePage__Disabled'}
              to={`/market-share/top-500-games`}
              onClick={(e) => (hasAccessToMarketShare ? customTableProps?.onMarketShareLinkClickCallback(marketShare.name) : e.preventDefault())}
            >
              <strong>
                {rowIndex + 1}. {marketShare.name}
              </strong>
            </Link>
          ),
          sortable: true,
          sortAccessor: 'name',
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left' },
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    {
      labelAccessor: ({ customTableProps }) => (
        <>
          {customTableProps && (
            <QuarterSelector
              selectedQuarter={customTableProps.selectedQuarter}
              quarters={calculatedQuarters}
              onQuarterChangeCallback={(e) => customTableProps?.handleQuarterChange(e)}
            />
          )}
        </>
      ),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_tooltip')}>{t('common:revenue_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                      utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].revenuePercentage, {
                        maximumFractionDigits: 2,
                      })}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                      utilsService.formatCurrency(marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].revenueTop500Value, {
                        shorten: true,
                        mantissa: 2,
                      })}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) => {
            if (customTableProps) {
              switch (customTableProps.marketShareNumberType.id) {
                case MarketShareNumberTypeId.Percentage:
                  return marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].revenuePercentage

                case MarketShareNumberTypeId.Value:
                  return marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].revenueTop500Value
              }
            }

            return 0
          },
          sortOrder: SortOrder.DESC,
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <>
                        <TrendIndicator
                          value={
                            quarterService.getChangeDataForPreviousQuarterValue(
                              QuarterDataKey.RevenuePercentage,
                              customTableProps.selectedQuarter.quarterIdentifier,
                              marketShare.quarters
                            ) * 100
                          }
                          maximumFractionDigits={2}
                          type={TrendIndicatorType.Value}
                        />
                      </>
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForPreviousQuarterValue(
                          QuarterDataKey.RevenueTop500Value,
                          customTableProps.selectedQuarter.quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) => {
            if (customTableProps) {
              switch (customTableProps.marketShareNumberType.id) {
                case MarketShareNumberTypeId.Percentage:
                  return quarterService.getChangeDataForPreviousQuarterValue(
                    QuarterDataKey.RevenuePercentage,
                    customTableProps.selectedQuarter.quarterIdentifier,
                    marketShare.quarters
                  )

                case MarketShareNumberTypeId.Value:
                  return quarterService.getChangeDataForPreviousQuarterValue(
                    QuarterDataKey.RevenueTop500Value,
                    customTableProps.selectedQuarter.quarterIdentifier,
                    marketShare.quarters
                  )
              }
            }

            return 0
          },
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_tooltip')}>{t('common:downloads_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                      utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].downloadsPercentage, {
                        maximumFractionDigits: 2,
                      })}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                      utilsService.formatNumber(marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].downloadsTop500Value, {
                        shorten: true,
                        mantissa: 2,
                      })}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) => {
            if (customTableProps) {
              switch (customTableProps.marketShareNumberType.id) {
                case MarketShareNumberTypeId.Percentage:
                  return marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].downloadsPercentage

                case MarketShareNumberTypeId.Value:
                  return marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier].downloadsTop500Value
              }
            }

            return 0
          },
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <>
                        <TrendIndicator
                          value={
                            quarterService.getChangeDataForPreviousQuarterValue(
                              QuarterDataKey.DownloadsPercentage,
                              customTableProps.selectedQuarter.quarterIdentifier,
                              marketShare.quarters
                            ) * 100
                          }
                          maximumFractionDigits={2}
                          type={TrendIndicatorType.Value}
                        />
                      </>
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForPreviousQuarterValue(
                          QuarterDataKey.DownloadsTop500Value,
                          customTableProps.selectedQuarter.quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) => {
            if (customTableProps) {
              switch (customTableProps.marketShareNumberType.id) {
                case MarketShareNumberTypeId.Percentage:
                  return quarterService.getChangeDataForPreviousQuarterValue(
                    QuarterDataKey.DownloadsPercentage,
                    customTableProps.selectedQuarter.quarterIdentifier,
                    marketShare.quarters
                  )

                case MarketShareNumberTypeId.Value:
                  return quarterService.getChangeDataForPreviousQuarterValue(
                    QuarterDataKey.DownloadsTop500Value,
                    customTableProps.selectedQuarter.quarterIdentifier,
                    marketShare.quarters
                  )
              }
            }

            return 0
          },
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
      ],
    },
    {
      labelAccessor: ({ customTableProps }) => (
        <>
          {customTableProps && (
            <Box sx={{ width: '100%' }}>
              <GRTooltip content={t(`market-share:${customTableProps.marketShareScopeType.id}_share_tooltip`)}>
                <FormControlLabel
                  sx={{ minWidth: { sm: 500, md: 500, lg: 200 }, display: 'flex', justifyContent: 'center', marginLeft: 0 }}
                  control={
                    <Switch
                      checked={customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value ? false : customTableProps.showScopeTypeShare}
                      onChange={() => customTableProps.setShowScopeTypeShare(!customTableProps?.showScopeTypeShare)}
                      color="primary"
                      size="small"
                      disabled={customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value}
                    />
                  }
                  label={t<string>(`market-share:show_${customTableProps.marketShareScopeType.id}_share`)}
                />
              </GRTooltip>
            </Box>
          )}
        </>
      ),
      columns: [
        {
          labelAccessor: t('market-share:top_x_games_list_title'),
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              {customTableProps && (
                <Grid container spacing={1}>
                  {getMarketShareQuarterTop3Games(
                    marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier],
                    customTableProps.selectedQuarter
                  ).map((game) => {
                    return (
                      <Grid item key={game.id} xs={12} sm={4}>
                        <MarketShareTopGameCard
                          game={game}
                          selectedQuarter={customTableProps.selectedQuarter}
                          showScopeTypeShare={customTableProps.showScopeTypeShare}
                          marketShareScopeType={customTableProps.marketShareScopeType}
                          marketShareNumberType={customTableProps.marketShareNumberType}
                        />
                      </Grid>
                    )
                  })}
                  {getMarketShareQuarterTop3Games(marketShare.quarters[customTableProps.selectedQuarter.quarterIdentifier], customTableProps.selectedQuarter)
                    .length === 0 && (
                    <Box padding={1} marginTop={1}>
                      {t('common:no_games_available')}
                    </Box>
                  )}
                </Grid>
              )}
            </>
          ),
          sortable: false,
          cellProps: { align: 'center' },
        },
      ],
      headerCellProps: { sx: { display: 'flex' } },
    },
  ]

  const [columns, setColumns] = useState<GRTableColumn<MarketShare, typeof customProps>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<MarketShare, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <div className="MarketShareQuarterOverview">
      <TableContainer ref={containerRef}>
        <GRTable
          rows={marketShares}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          scroller={containerRef}
          customProps={customProps}
          maxRows={100}
        />
      </TableContainer>
    </div>
  )
}

export default MarketShareQuarterOverview
