import { useState, FC, useMemo, ChangeEvent, useEffect, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Grid, Card, CardHeader, CardContent, Divider } from '@mui/material'

import { usePostPublicFilesMutation } from '../../../../../api/core'
import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { useQueryStatusSnackbarMessage } from '../../../../../hooks/useQueryStatusSnackbarMessage'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { resize, dataUrltoBlob, getDimensions } from '../../../../utils/image'
import { EventScreenshot } from '../../types/EventScreenshot'
import { EventScreenshotPreviewMode } from '../../types/EventScreenshotPreviewMode'
import { EventScreenshotColumnAdder } from './EventScreenshotColumnAdder/EventScreenshotColumnAdder'
import { EventScreenshotDialog } from './EventScreenshotDialog/EventScreenshotDialog'
import { EventScreenshotPreviewModeToggler } from './EventScreenshotPreviewModeToggler/EventScreenshotPreviewModeToggler'
import { EventScreenshotsGallery } from './EventScreenshotsGallery/EventScreenshotsGallery'
import { EventScreenshotsTable } from './EventScreenshotsTable/EventScreenshotsTable'
import { EventScreenshotsUploader } from './EventScreenshotsUploader/EventScreenshotsUploader'

type EventScreenshotsContainerProps = {
  onScreenshotsUploaded: (urls: string[]) => void
  screenshots?: string[]
  onScreenshotSelectionChange: (notUploadedFiles: boolean) => void
}

type RemoveScreenshotConfirmDialogData = EventScreenshot

export const EventScreenshotsContainer: FC<EventScreenshotsContainerProps> = ({ onScreenshotsUploaded, screenshots = [], onScreenshotSelectionChange }) => {
  const { t } = useTranslation()
  const [previewMode, setPreviewMode] = useState<EventScreenshotPreviewMode>(EventScreenshotPreviewMode.List)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [removeScreenshotConfirmDialogData, setRemoveScreenshotConfirmDialogData] = useState<ConfirmDialogData<RemoveScreenshotConfirmDialogData>>()
  const [uploadFiles, uploadFilesMutation] = usePostPublicFilesMutation()
  const [resizingFiles, setResizingFiles] = useState<boolean>(false)
  const [gridValue, setGridValue] = useState<number | string>(5)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const onOpenDialog = () => {
    setOpenDialog(false)
  }
  const [screenshotData, setScreenshotData] = useState<EventScreenshot>()

  // update not uploaded files to parent component
  useEffect(() => {
    const notUploadedFiles = selectedFiles.length ? true : false
    onScreenshotSelectionChange(notUploadedFiles)
  }, [onScreenshotSelectionChange, selectedFiles])

  const handleFileSelectionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(Array.from(e.target.files || []))
  }, [])

  const onOpenScreenshotDialog = (screenshot: EventScreenshot) => {
    setScreenshotData(screenshot)
    setOpenDialog(true)
  }

  const resizeFiles = async (files: File[]) => {
    const resizingPromises = files.map(async (file, index) => {
      try {
        // check if image requires resizing
        const imageDimensions = await getDimensions(file)
        const requiresResize = file.type !== 'image/jpeg' || imageDimensions.width > 2000 || imageDimensions.height > 2000 || file.size > 5000000

        // resize the image and convert to jpeg
        const resizedFile = requiresResize
          ? await resize(file, { width: 2000, height: 2000, quality: 0.9, mimeType: 'image/jpeg' })
          : await resize(file, { mimeType: 'image/jpeg' })

        // rename the screenshot to jpg
        const fileExtension = files[index].name.split('.').pop()
        const filename = files[index].name.split('.' + fileExtension).shift() + '.jpg'
        if (resizedFile) {
          return new File([dataUrltoBlob(resizedFile)], filename, { type: 'image/jpeg' })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error while resizing screenshot ${file.name}`, e)
      }
    })

    const resizedFiles = await Promise.all(resizingPromises)

    return resizedFiles.filter((file) => file !== undefined) as File[]
  }

  const handleUpload = useCallback(async () => {
    setResizingFiles(true)
    const resizedFiles = await resizeFiles(selectedFiles)
    uploadFiles({ files: resizedFiles, type: 'screenshot', route: 'public' })
      .unwrap()
      .then(() => {})
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Error uploading screenshots', e)
      })
      .finally(() => {
        setSelectedFiles([])
        setResizingFiles(false)
      })
  }, [selectedFiles, uploadFiles])

  const handleRemoveScreenshot = (screenshot: EventScreenshot) => {
    setRemoveScreenshotConfirmDialogData({
      title: t('internal-live-events:remove_screenshot_confirm_dialog_title'),
      confirmText: t('internal-live-events:remove_screenshot_confirm_dialog_confirm_text'),
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data: screenshot,
    })
  }

  const handleRemoveScreenshotConfirmed = (dialogData?: ConfirmDialogData<RemoveScreenshotConfirmDialogData>) => {
    if (dialogData?.data) {
      const screenshot = dialogData.data
      if (typeof screenshot.originalData === 'string') {
        onScreenshotsUploaded(screenshots.filter((url) => url !== screenshot.name))
      } else {
        setSelectedFiles(selectedFiles.filter((file) => file.name !== screenshot.name))
      }
    }

    setRemoveScreenshotConfirmDialogData(undefined)
  }

  // once file upload is complete report them to parent component
  useEffect(() => {
    if (uploadFilesMutation?.isSuccess) {
      const fileUrls = uploadFilesMutation?.data?.map((file) => file.url) || []
      uploadFilesMutation.reset()
      onScreenshotsUploaded([...screenshots, ...fileUrls])
    } else if (uploadFilesMutation.isError) {
      uploadFilesMutation.reset()
    }
  }, [onScreenshotsUploaded, screenshots, uploadFilesMutation, uploadFilesMutation.data, uploadFilesMutation?.isSuccess])

  const screenshotsData: EventScreenshot[] = useMemo(
    () =>
      screenshots
        .map((url) => ({ name: url, originalData: url } as EventScreenshot))
        .concat(
          selectedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            originalData: file,
          }))
        ),
    [screenshots, selectedFiles]
  )

  const handleReorderScreenshots = (screenshotsDataNewOrder: EventScreenshot[]) => {
    if (screenshotsData !== screenshotsDataNewOrder) {
      const screenshotsnewOrder = screenshotsDataNewOrder.map((screenshot) => screenshot.name)
      onScreenshotsUploaded(screenshotsnewOrder)
      return
    }
  }

  useQueryStatusSnackbarMessage({ ...uploadFilesMutation, successMessage: t('internal-live-events:screenshot_upload_success_message') })
  const isUploadingScreenshots = uploadFilesMutation.isLoading || resizingFiles

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Grid container spacing={2} wrap="wrap" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Trans i18nKey="internal-live-events:screenshots_container_title" />
              </Grid>
              <Grid item>
                <Grid container gap={2} alignItems="center">
                  {previewMode === EventScreenshotPreviewMode.Grid ? <EventScreenshotColumnAdder gridValue={gridValue} onChange={setGridValue} /> : null}
                  <EventScreenshotPreviewModeToggler value={previewMode} onChange={setPreviewMode} />
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        {previewMode === EventScreenshotPreviewMode.List ? (
          <>
            <EventScreenshotsTable
              screenshots={screenshotsData}
              onRemoveScreenshot={handleRemoveScreenshot}
              onOpenScreenshotDialog={onOpenScreenshotDialog}
              onReorderScreenshots={handleReorderScreenshots}
            />
          </>
        ) : (
          <EventScreenshotsGallery
            screenshots={screenshotsData}
            rowLengthValue={gridValue}
            onRemoveScreenshot={handleRemoveScreenshot}
            onOpenScreenshotDialog={onOpenScreenshotDialog}
          />
        )}
        <Divider />
        <CardContent>
          <EventScreenshotsUploader files={selectedFiles} onChange={handleFileSelectionChange} onUpload={handleUpload} isUploading={isUploadingScreenshots} />
        </CardContent>
      </Card>
      <ConfirmDialog
        open={!!removeScreenshotConfirmDialogData}
        onClose={handleRemoveScreenshotConfirmed}
        confirmDialogData={removeScreenshotConfirmDialogData}
      />
      <EventScreenshotDialog open={openDialog} onClose={onOpenDialog} screenshotData={screenshotData} />
    </>
  )
}
