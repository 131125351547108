import { t } from 'i18next'
import { FC, useRef, useMemo, useState, useCallback, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AddReaction, Chat, Delete, Edit, EmojiEmotions } from '@mui/icons-material'
import { TableContainer, Card, Grid, Typography } from '@mui/material'

import { useGetUserSettingsQuery } from '../../../../../api/combined'
import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTableColumn, GRTable } from '../../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { UserSettingKeys } from '../../../../../features/account/types/UserSettings'
import { useQueryStatusSnackbarMessage } from '../../../../../hooks/useQueryStatusSnackbarMessage'
import { useRequiredParams } from '../../../../../hooks/useRequiredParams'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { useCloneEventsMutation, useDeleteTrackedGameEventByDateMutation } from '../../../../api/internal'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { CheckedStatusCircle } from '../../../../components/CheckedStatusCircle/CheckedStatusCircle'
import { EventCloner } from '../../../../components/EventCloner/EventCloner'
import { InternalTrackingEventByDate } from '../../../../types/InternalTrackingEvent'
import { useLiveEventsTrackingEventsSearchParams } from '../../hooks/useLiveEventsTrackingEventsSearchParams'
import { AnalystEventNoteDialog, AnalystNoteDialogData } from '../AnalystEventNoteDialog/AnalystEventNoteDialog'

/**
 * Table component for displaying daily events for a tracked game
 */
type TrackedGameDailyEventsTableProps = {
  events?: InternalTrackingEventByDate[]
  isLoading?: boolean
}

type EventDeleteConfirmData = {
  trackingEventId: string
  date: Date
}

type ActionTooltipButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  icon: ReactNode
  onClick: () => void
  disableMaxWidth?: boolean
  disableInteractive?: boolean
  content?: ReactNode
}

const ActionTooltipButton: FC<ActionTooltipButtonProps> = ({ icon, onClick, color = 'primary', content, disableInteractive, disableMaxWidth }) => {
  const button = (
    <ActionButton color={color} onClick={onClick}>
      {icon}
    </ActionButton>
  )
  return (
    <>
      {content ? (
        <GRTooltip content={content} disableInteractive={disableInteractive} disableMaxWidth={disableMaxWidth}>
          {button}
        </GRTooltip>
      ) : (
        button
      )}
    </>
  )
}

export const TrackedGameDailyEventsTable: FC<TrackedGameDailyEventsTableProps> = ({ events, isLoading }) => {
  const containerRef = useRef(null)
  const navigate = useNavigate()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams } = useLiveEventsTrackingEventsSearchParams()
  const [cloneEvents, cloneEventsMutation] = useCloneEventsMutation()
  const [deleteEvent] = useDeleteTrackedGameEventByDateMutation()
  const [deleteEventsDialogData, setDeleteEventDialogData] = useState<ConfirmDialogData<EventDeleteConfirmData>>()
  const [analystNotesDialogData, setAnalystNotesDialogData] = useState<AnalystNoteDialogData>()
  const { data: userSettings } = useGetUserSettingsQuery()
  const liveEventNotes = userSettings && userSettings[UserSettingKeys.liveeventsAnalystNotes]

  const dailyEventsColumns: GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-common:name" />,
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.name,
        sortable: true,
        sortAccessor: ({ row }) => row.name,
      },
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:event_type" />,
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row }) => row.type,
        sortable: true,
        sortAccessor: ({ row }) => row.type,
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:category" />,
        accessor: ({ row }) => row.category,
        sortable: true,
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:active" />,
        accessor: ({ row }) => <CheckedStatusCircle value={row.active} />,
        sortable: true,
        sortAccessor: ({ row }) => (row.active ? 1 : 0),
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:highlighted" />,
        accessor: ({ row }) => <CheckedStatusCircle value={row.highlighted} />,
        sortable: true,
        sortAccessor: ({ row }) => (row.highlighted ? 1 : 0),
      },
      {
        headerCellProps: { sx: { width: 160 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, customTableProps }) => {
          const eventNote = customTableProps?.liveEventNotes ? customTableProps.liveEventNotes[row.eventId] : undefined
          return (
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <ActionTooltipButton
                  content={
                    eventNote ? (
                      <div style={{ overflowY: 'auto', paddingRight: '5px', maxHeight: '40vh', maxWidth: '20vw', scrollbarWidth: 'thin' }}>
                        <HtmlContentParser rawHtml={eventNote} parserOptions={liveEventReviewModalParserOptions(customTableProps?.gameId || '')} />
                      </div>
                    ) : undefined
                  }
                  disableMaxWidth={true}
                  disableInteractive={false}
                  color={!!eventNote ? 'success' : 'primary'}
                  onClick={() => handleNotesDialogOpen({ eventId: row.eventId, eventNote: eventNote, eventNotes: customTableProps?.liveEventNotes })}
                  icon={<Chat fontSize="small" />}
                />
              </Grid>
              <Grid item>
                <ActionTooltipButton onClick={() => navigate(`event/${row.eventId}/edit`)} icon={<Edit fontSize="small" />}></ActionTooltipButton>
              </Grid>
              <Grid item>
                <ActionTooltipButton
                  color="warning"
                  onClick={() => handleDeleteEvent({ trackingEventId: row.eventId, date: customTableProps!.date })}
                  icon={<Delete fontSize="small" />}
                />
              </Grid>
              <Grid item>
                <ActionTooltipButton
                  content={`${t('internal-common:motivations')}: ${row.analyzed ? t('game-header:analyzed') : t('internal-common:not_analyzed')}`}
                  onClick={() => navigate(`event/${row.eventId}/analysis`)}
                  color={row.analyzed ? 'success' : 'primary'}
                  icon={row.analyzed ? <EmojiEmotions fontSize="small" /> : <AddReaction fontSize="small" />}
                />
              </Grid>
            </Grid>
          )
        },
      },
    ],
    [navigate]
  )

  const handleCloneEvents = (date: Date) => {
    cloneEvents({
      gameId: trackedGameId,
      sourceDate: {
        start: date.getTime(),
        end: date.getTime(),
      },
      targetDate: {
        start: parsedParams.date.getTime(),
        end: parsedParams.date.getTime(),
      },
    })
  }
  const handleNotesDialogOpen = (data: AnalystNoteDialogData) => {
    setAnalystNotesDialogData(data)
  }

  const handleAnalystNotesDialogClose = () => {
    setAnalystNotesDialogData(undefined)
  }

  const handleDeleteEvent = (data: EventDeleteConfirmData) => {
    setDeleteEventDialogData({
      title: t('internal-live-events:delete_game_event_from_date_confirm_dialog_title'),
      confirmText: t('internal-live-events:delete_game_event_from_date_confirm_dialog_confirm_text'),
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data,
    })
  }

  const handleDeleteEventConfirm = (dialogData?: ConfirmDialogData<EventDeleteConfirmData>) => {
    if (dialogData?.data) {
      deleteEvent({ trackingEventId: dialogData.data.trackingEventId, date: dialogData.data.date.getTime() })
    }

    setDeleteEventDialogData(undefined)
  }

  useQueryStatusSnackbarMessage({
    ...cloneEventsMutation,
    successMessage: t('internal-live-events:clone_events_success_message'),
  })

  const customTableProps = useMemo(() => {
    return {
      liveEventNotes: liveEventNotes,
      date: parsedParams.date,
      gameId: trackedGameId,
    }
  }, [liveEventNotes, parsedParams.date, trackedGameId])

  const [columns, setColumns] = useState<GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[]>(dailyEventsColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<InternalTrackingEventByDate, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={isLoading}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey={(event) => event.eventId}
          rows={events || []}
          customProps={customTableProps}
          scroller={containerRef}
          striped
          gridlines
          noRowsLabel={
            <Grid container direction="column" alignItems="center" rowSpacing={2}>
              <Grid item>
                <Typography variant="body1">{t('internal-live-events:no_events_for_selected_date_help_text')}</Typography>
              </Grid>
              <Grid item>
                <EventCloner onClone={(date) => handleCloneEvents(date)} />
              </Grid>
            </Grid>
          }
        />
      </TableContainer>
      <ConfirmDialog open={!!deleteEventsDialogData} onClose={handleDeleteEventConfirm} confirmDialogData={deleteEventsDialogData} />
      <AnalystEventNoteDialog open={!!analystNotesDialogData} onClose={handleAnalystNotesDialogClose} data={analystNotesDialogData} />
    </>
  )
}
