import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import { Game } from '../..'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { LockedData } from '../../../../components/LockedData/LockedData'
import NotAvailableIcon from '../../../../components/NotAvailableIcon/NotAvailableIcon'
import utilsService from '../../../../services/UtilsService'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { RevenueAndDownloadEstimates } from '../../../estimates/services/RevenueAndDownloadEstimates'
import { useDateTimeFormatter } from '../../../live-events/hooks/useDateTimeFormatter'
import { useCurrentMarket } from '../../../markets'
import { useIsGameOpenForEveryone } from '../../../settings'
import { useGetGameVersionInfo, useOwnGameCheck } from '../../hooks/gameHooks'
import { GameKeyIndicatorType } from '../../types/GameKeyIndicatorType'
import GameSkillThinkingMeter from '../GameSkillThinkingMeter/GameSkillThinkingMeter'
import './GameKeyIndicator.scss'

interface Props {
  type: GameKeyIndicatorType
  game: Game
  marketIso: string
  smallSize?: boolean
}

const QuestionTooltip: React.FC<{ explanation: string; children: string | React.ReactElement; hideQuestionMark?: boolean }> = ({
  explanation,
  children,
  hideQuestionMark = false,
}) => (
  <GRTooltip content={explanation}>
    {children}
    <Typography color="primary" display="inline" sx={{ fontSize: '14px', lineHeight: 0 }}>
      {hideQuestionMark ? '' : ' ?'}
    </Typography>
  </GRTooltip>
)

const DisplayData: React.FC<{ value: number }> = ({ value }) => {
  const { t } = useTranslation()
  return value !== 0 ? (
    <strong>{Math.round(value * 100)}%</strong>
  ) : (
    <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} fontSize="18px" />
  )
}

const GameKeyIndicator: React.FC<Props> = ({ type, game, marketIso, smallSize }) => {
  const { t } = useTranslation()
  const { currentMarketIso: mainMarketIso, englishLanguageMarketIsos } = useCurrentMarket()
  const availableMarketIsoForUserLanguage = useMemo(() => {
    return Object.keys(game.versions)
      .filter((versionMarket) => englishLanguageMarketIsos.includes(versionMarket))
      .sort((a, b) => englishLanguageMarketIsos.indexOf(a) - englishLanguageMarketIsos.indexOf(b))[0]
  }, [englishLanguageMarketIsos, game.versions])
  const gameVersion = game.versions[mainMarketIso] === undefined ? game.version : game.versions[mainMarketIso]

  const { data: gameVersionInfo } = useGetGameVersionInfo(game.appId, availableMarketIsoForUserLanguage || mainMarketIso, gameVersion)

  const maxRank = 1000
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const hasAccessToDemographics = useRoleCheck(RoleEnum.demographics)
  const isOwnGame = useOwnGameCheck(game)

  const demographicsDataUnlocked = hasAccessToDemographics || isGameOpenForEveryone
  const estimates = new RevenueAndDownloadEstimates(game, marketIso)
  const dateFormatter = useDateTimeFormatter()
  const showData = utilsService.demographicsIsAvailableForMarket(marketIso) && game.demographics && game.demographics[marketIso]

  const isFutureReleaseDate = (releaseDate: number) => {
    const now = Date.now()
    return releaseDate > now
  }

  const getDaysDifference = (releaseDate: number) => {
    return isFutureReleaseDate(releaseDate)
      ? `- ${utilsService.getDayDifferenceFromTwoDates(Date.now(), releaseDate)}`
      : utilsService.getDayDifferenceFromTwoDates(releaseDate, Date.now())
  }

  return (
    <div className={smallSize ? 'GameKeyIndicator GameKeyIndicator--Small' : 'GameKeyIndicator'}>
      {type === GameKeyIndicatorType.FeatureIndicators && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:feature_indicators_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:tooltip_gps_description')}>{t('common:game_power_score')}</QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {game.gpsPerMarket[marketIso] ? (
                    Math.round(game.gpsPerMarket[marketIso])
                  ) : (
                    <NotAvailableIcon tooltipContent={t('common:analysis_not_available')} fontSize="18px" />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:skill_thinking_description')}>{t('common:skill_thinking')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {game.gpsPerMarket[marketIso] ? (
                  <GameSkillThinkingMeter sensomotoric={game.sensomotoric} cognitive={game.cognitive}></GameSkillThinkingMeter>
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:analysis_not_available')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.DemographicsGender && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:gender')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:male_description')}>{t('common:male')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {!demographicsDataUnlocked ? (
                  <QuestionTooltip explanation={t('feature:upgrade_your_plan_to_unlock_this_feature')} hideQuestionMark>
                    <LockedData />
                  </QuestionTooltip>
                ) : showData ? (
                  <DisplayData value={game.demographics[marketIso].demographicsMale} />
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:data_not_available_for_market')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:female_description')}>{t('common:female')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {!demographicsDataUnlocked ? (
                  <QuestionTooltip explanation={t('feature:upgrade_your_plan_to_unlock_this_feature')} hideQuestionMark>
                    <LockedData />
                  </QuestionTooltip>
                ) : showData ? (
                  <DisplayData value={game.demographics[marketIso].demographicsFemale} />
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:data_not_available_for_market')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.DemographicsAge && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:age')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:age16_24_description')}>{t('common:age16_24')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {!demographicsDataUnlocked ? (
                  <QuestionTooltip explanation={t('feature:upgrade_your_plan_to_unlock_this_feature')} hideQuestionMark>
                    <LockedData />
                  </QuestionTooltip>
                ) : showData ? (
                  <DisplayData value={game.demographics[marketIso].demographicsAge16_24} />
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:data_not_available_for_market')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:age25_44_description')}>{t('common:age25_44')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {!demographicsDataUnlocked ? (
                  <QuestionTooltip explanation={t('feature:upgrade_your_plan_to_unlock_this_feature')} hideQuestionMark>
                    <LockedData />
                  </QuestionTooltip>
                ) : showData ? (
                  <DisplayData value={game.demographics[marketIso].demographicsAge25_44} />
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:data_not_available_for_market')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:age45_plus_description')}>{t('common:age45_plus')}</QuestionTooltip>
              </Grid>
              <Grid item>
                {!demographicsDataUnlocked ? (
                  <QuestionTooltip explanation={t('feature:upgrade_your_plan_to_unlock_this_feature')} hideQuestionMark>
                    <LockedData />
                  </QuestionTooltip>
                ) : showData ? (
                  <DisplayData value={game.demographics[marketIso].demographicsAge45} />
                ) : (
                  <NotAvailableIcon tooltipContent={t('common:data_not_available_for_market')} fontSize="18px" />
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.SustainedRanks && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:sustained_ranks_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:sustained_grossing_rank_description')}>{t('common:grossing_rank')}</QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!isOwnGame ? (
                    <strong>
                      {game.getSustainedGrossingRankForMarket(marketIso) <= maxRank ? game.getSustainedGrossingRankForMarket(marketIso) : `${maxRank}+`}
                    </strong>
                  ) : (
                    <NotAvailableIcon fontSize="18px" tooltipContent={t('common:not_available_for_game_concepts')} />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:sustained_download_rank_description')}>{t('common:download_rank')}</QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!isOwnGame ? (
                    <strong>
                      {game.getSustainedDownloadRankForMarket(marketIso) <= maxRank ? game.getSustainedDownloadRankForMarket(marketIso) : `${maxRank}+`}
                    </strong>
                  ) : (
                    <NotAvailableIcon fontSize="18px" tooltipContent={t('common:not_available_for_game_concepts')} />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.Performance30Days && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:monthly_performance_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_past_month_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!isOwnGame ? (
                    estimates.revenue30Day.formatCurrency({ shorten: true })
                  ) : (
                    <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} fontSize="18px" />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:downloads_past_month_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:downloads_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!isOwnGame ? (
                    estimates.downloads30Day.format()
                  ) : (
                    <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} fontSize="18px" />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_downloads_ratio_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_downloads_ratio_shorthand')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!estimates.revenueAndDownloadsRatio30Day.compareThreshold('revenue', 0) ? (
                    estimates.revenueAndDownloadsRatio30Day.formatCurrency({ threshold: 0, maxFractionDigits: 3 })
                  ) : isOwnGame ? (
                    <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} fontSize="18px" />
                  ) : (
                    <NotAvailableIcon tooltipContent={t('common:revenue_download_ratio_not_accurate')} fontSize="18px" />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.Performance180Days && !estimates.downloads180Day.format().includes('100') && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:half_year_performance_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_past_half_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{estimates.revenue180Day.formatCurrency({ shorten: true })}</strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:downloads_past_half_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:downloads_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{estimates.downloads180Day.format()}</strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_downloads_ratio_past_half_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_downloads_ratio_shorthand')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{estimates.revenueAndDownloadsRatio180Day.formatCurrency({ threshold: 0, maxFractionDigits: 3 })}</strong>
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.Performance360Days && !estimates.downloads360Day.format().includes('100') && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:annual_perfomance_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_past_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{estimates.revenue360Day.formatCurrency({ shorten: true })}</strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:downloads_past_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:downloads_text')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{estimates.downloads360Day.format()}</strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={`${t('common:revenue_downloads_ratio_past_year_description')} (iOS ${marketIso.toUpperCase()})`}>
                  {t('common:revenue_downloads_ratio_shorthand')}
                </QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>
                  {!estimates.revenueAndDownloadsRatio360Day.compareThreshold('revenue', 0) ? (
                    estimates.revenueAndDownloadsRatio360Day.formatCurrency({ threshold: 0, maxFractionDigits: 3 })
                  ) : isOwnGame ? (
                    <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} fontSize="18px" />
                  ) : (
                    <NotAvailableIcon tooltipContent={t('common:revenue_download_ratio_not_accurate')} fontSize="18px" />
                  )}
                </strong>
              </Grid>
            </Grid>
          </div>
        </>
      )}

      {type === GameKeyIndicatorType.ReleaseDate && (
        <>
          <div className="GameKeyIndicator__Title">{t('common:release_text')}</div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:release_date_text')}>{t('common:date')}</QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{gameVersionInfo ? dateFormatter.format(gameVersionInfo.releaseDate) : dateFormatter.format(game.released)}</strong>
              </Grid>
            </Grid>
          </div>

          <div className="GameKeyIndicator__Container">
            <Grid container spacing={2}>
              <Grid item xs>
                <QuestionTooltip explanation={t('common:days_since_release_description')}>{t('common:days_since_text')}</QuestionTooltip>
              </Grid>
              <Grid item>
                <strong>{gameVersionInfo ? getDaysDifference(gameVersionInfo.releaseDate) : getDaysDifference(game.released)}</strong>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  )
}

export default GameKeyIndicator
