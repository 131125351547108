import { useMemo } from 'react'

import { endOfDayTimestamp } from '../../../helpers/endOfDayTimestamp'
import { useAverageRevenuePerDailyActiveUser } from '../../active-users/hooks/useAverageRevenuePerDailyActiveUser'
import { usePerformanceChartActiveUserData } from '../../active-users/hooks/usePerformanceChartActiveUserData'
import { useEstimatesList, useGameEstimateData } from '../../estimates/hooks/estimateHooks'
import { EstimatePlatformType } from '../../estimates/types/EstimatePlatformType'
import { useTrackedEvents } from '../../live-events/hooks/useTrackedEvents'
import { LiveEventTrackerSearchMethod } from '../../live-events/types/LiveEventTrackerSearchMethod'
import { useCurrentMarket } from '../../markets/hooks/useCurrentMarket'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { useTopGrossingGameRanks } from '../../top-game/hooks/topGrossingGameHooks'

const defaultPlatformTypes = [EstimatePlatformType.Phone, EstimatePlatformType.Tablet]

/**
 * Hook that collects all the necessary data for performance chart
 */

type UseGameUpdatePerformanceDataHookParams = {
  appId: number
  marketIso: string
  rollingDays: number
  granularity?: GranularityValue
  platformTypes?: EstimatePlatformType[]
  gameId?: string
}

export const useGameUpdatePerformanceData = ({
  appId,
  marketIso,
  platformTypes = defaultPlatformTypes,
  rollingDays,
  granularity = GranularityValue.Day,
  gameId,
}: UseGameUpdatePerformanceDataHookParams) => {
  const appIds = useMemo(() => {
    return [appId]
  }, [appId])

  const markets = useMemo(() => {
    return [marketIso]
  }, [marketIso])

  const filters = useMemo(() => {
    return {
      rollingDays,
      platformTypes,
      marketIsos: markets,
      granularity,
    }
  }, [rollingDays, platformTypes, markets, granularity])

  const { currentMarketIso } = useCurrentMarket()
  const { estimatesList, isLoading: isEstimatesLoading, error: estimatesError } = useGameEstimateData({ appIds, markets, platformTypes })
  const estimates = useEstimatesList({ data: estimatesList, filters })
  const marketEstimates = estimates.markets.find((estimate) => estimate?.marketIso === marketIso)
  const { topGrossingRanks, freeRanks, isLoading: isRanksLoading, error: rankError } = useTopGrossingGameRanks({ appId, marketIso, granularity })
  const {
    dailyActiveUsers,
    monthlyActiveUsers,
    error: dauAveragesError,
    isLoading: isDauAveragesLoading,
  } = usePerformanceChartActiveUserData({ appId, granularity })
  const trackedEventsQuery = useTrackedEvents({
    searchMethod: LiveEventTrackerSearchMethod.games,
    gameIds: [gameId!],
    startTimestamp: 0,
    endTimestamp: endOfDayTimestamp(),
    marketIso: currentMarketIso,
  })

  const performanceValues = trackedEventsQuery.data?.performanceValues?.[gameId!] || []
  const averageRevenuePerDailyActiveUserItems = performanceValues
    .filter((value: any) => !!value.averageRevenuePerDailyActiveUser)
    .map((revenuePerActiveUser: any) => {
      return {
        date: new Date(revenuePerActiveUser.date).getTime(),
        value: revenuePerActiveUser.averageRevenuePerDailyActiveUser || 0,
      }
    })

  const averageRevenuePerDailyActiveUser = useAverageRevenuePerDailyActiveUser({
    averageRevenuePerDailyActiveUserItems,
    granularity,
  })

  const [minDate, maxDate] = useMemo(() => {
    const { tsMin = Number.MAX_SAFE_INTEGER, tsMax = 0 } = marketEstimates || {}
    const dates = topGrossingRanks
      .map((tgr) => tgr.ts)
      .concat(freeRanks.map((fr) => fr.ts))
      .concat(dailyActiveUsers.map((dau) => dau.date))
      .concat(monthlyActiveUsers.map((mau) => mau.date))
      .concat(averageRevenuePerDailyActiveUser.map((arpdau) => arpdau.date))
      .concat([tsMin, tsMax])

    return [Math.min(...dates), Math.max(...dates)]
  }, [averageRevenuePerDailyActiveUser, dailyActiveUsers, freeRanks, marketEstimates, monthlyActiveUsers, topGrossingRanks])

  return {
    estimates: marketEstimates?.estimates || [],
    topGrossingRanks,
    freeRanks,
    dailyActiveUsers,
    monthlyActiveUsers,
    minDate,
    maxDate,
    isLoading: isEstimatesLoading || isRanksLoading || isDauAveragesLoading,
    error: estimatesError || rankError || dauAveragesError,
    averageRevenuePerDailyActiveUser,
  }
}
