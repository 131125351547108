import { format, isSameDay, isSameMonth, isSameWeek, isWithinInterval, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { AttributeModelNames, VisualAnalysis, VisualAnalysisModel } from '../api/connect'
import { Category, CompareMultiGames, DetailedFeature, DetailedFeatureChoice, FeatureFactsResponse, TrendingValue } from '../api/core'
import { useGetTopGamesMultiMarketQuery } from '../api/top-grossing'
import { DateRangeValue } from '../components/DateRangePicker/DateRangePicker'
import { PerformanceChartV2DataType } from '../components/PerformanceChartV2/PerformanceChartV2DataType'
import { useDemographicsDataAccessCheck, useMotivationsAccessCheck } from '../features/account/hooks/roleHooks'
import { CompareFeatureRow } from '../features/compare-games/hooks/useCompareFeatureRows'
import { GameEstimates, iosEstimate, MarketsPerformanceEstimates, PerformanceEstimate } from '../features/estimates'
import featureService from '../features/feature/services/FeatureService'
import { GameFeatureTableRow } from '../features/game-features/types/types'
import { DemographicTypes, EstimateTypes, Game, RevenueDownloadsRatioTypes } from '../features/game/types/Game'
import { GameAndAnalysis } from '../features/game/types/GameAndAnalysis'
import { useDateTimeFormatter } from '../features/live-events/hooks/useDateTimeFormatter'
import type { MarketExplorerGamesDataTableRow } from '../features/market-explorer/components/MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTable'
import { FeatureDataRow } from '../features/market-explorer/hooks/featureDataHooks'
import { MarketExplorerSegmentConfiguration } from '../features/market-explorer/types/MarketExplorerSegmentConfiguration'
import { MarketShare } from '../features/market-share/types/MarketShare'
import { MarketShareScopeType } from '../features/market-share/types/MarketShareScopeType'
import { MarketShareSubpage } from '../features/market-share/types/MarketShareSubpage'
import { useCurrentMarket } from '../features/markets/hooks/useCurrentMarket'
import { Quarter } from '../features/quarter/types/Quarter'
import { GranularityValue } from '../features/revenue-and-downloads/types/Filters'
import { TopGame } from '../features/top-game/types/TopGame'
import { UPDATE_IMPACTS_SINCE } from '../features/update-history/constants/constants'
import { useGameUpdatePerformanceData } from '../features/update-history/hooks/useGameUpdatePerformanceData'
import { UpdateImpactAggregates } from '../features/update-impacts/services/UpdateImpactAggregates'
import { ComparisonInterval } from '../features/update-impacts/types/ComparisonInterval'
import { TableRowUpdateImpact } from '../features/update-impacts/types/TableRowUpdateImpact'
import { getAllTopCounts } from '../features/visual-explorer/util/calculations'
import { visualsTranslationMap } from '../features/visuals/util/helpers'
import { nameComparator } from '../helpers/arrays'
import languageService from '../services/LanguageService'
import motivationService from '../services/MotivationService'
import { revenueIsAvailableForMarket } from '../services/RevenueService'
import utilsService from '../services/UtilsService'
import { useConventionalCategoriesMap, useConventionalGenresMap, useConventionalSubgenresMap } from './useConventionalHooks'

export interface ExportRow {
  [key: string]: string | number
}

interface ExportHelperCategory {
  name: any
  id: string
  ordr: number // order
  isOpen: boolean
  features: ExportHelperFeature[]
}

interface ExportHelperFeature {
  categoryId: string
  categoryName: string
  choiceIds: number[]
  choices: string[]
  effects: number[]
  featureId: number
  featureName: string
  order: number
  similar: boolean
}

/**
 * Top Grossing Page Data Export Hook
 * @param games Top Games
 * @param queryLimit Quantity of games to be displayed
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows and Header rows for generateExport function
 */
export const useExportDataTopGrossingPage = (games: TopGame[], queryLimit: number, isExportRequested: boolean) => {
  const [headerRows, setHeaderRows] = useState<string[][]>([])
  const [exportRows, setExportRows] = useState<{ [key: string]: string | number }[]>([])

  const { map: conventionalGenresMap } = useConventionalGenresMap()
  const { map: conventionalCategoriesMap } = useConventionalCategoriesMap()
  const { currentMarketIso } = useCurrentMarket()

  useEffect(() => {
    if (isExportRequested) {
      const newExportRows =
        games?.map((topGame) => {
          const subgenreName = topGame.conventionalSubgenre
          const genreName =
            conventionalGenresMap[topGame.conventionalGenreId] !== undefined ? conventionalGenresMap[topGame.conventionalGenreId].genre.name : ''
          const categoryName =
            conventionalCategoriesMap[topGame.conventionalCategoryId] !== undefined ? conventionalCategoriesMap[topGame.conventionalCategoryId].name : ''

          return {
            [t('common:grossing_rank')]: !topGame.rank || topGame.rank > queryLimit ? `${queryLimit}+` : topGame.rank,
            [t('common:free_rank')]: !topGame.drank || topGame.drank > queryLimit ? `${queryLimit}+` : topGame.drank,
            'App ID': topGame.appId,
            [t('common:game')]: topGame.name,
            [t('common:publisher')]: topGame.artist,
            [t('common:conventional_subgenre')]: subgenreName,
            [t('common:conventional_genre')]: genreName,
            [t('common:conventional_category')]: categoryName,
            [`${t('common:previous_day')} / ${t('common:revenue_text')} $`]: topGame.getRevenueForMarket(EstimateTypes.REVENUE_LATEST, currentMarketIso),
            [`${t('common:previous_day')} / ${t('common:downloads_text')}`]: topGame.getDownloadsForMarket(EstimateTypes.DOWNLOADS_LATEST, currentMarketIso),
            [t('common:game_power_score')]: topGame.gps ? Math.round(topGame.gps) : t('common:not_available_shorthand'),
            [`${t('common:30_days_revenue')} $`]: topGame.getRevenueForMarket(EstimateTypes.REVENUE_30_DAY, currentMarketIso),
            [t('common:30_days_downloads')]: topGame.getDownloadsForMarket(EstimateTypes.DOWNLOADS_30_DAY, currentMarketIso),
            [`${t('common:60_days_revenue')} $`]: topGame.getRevenueForMarket(EstimateTypes.REVENUE_60_DAY, currentMarketIso),
            [t('common:60_days_downloads')]: topGame.getDownloadsForMarket(EstimateTypes.DOWNLOADS_60_DAY, currentMarketIso),
          }
        }) || []

      const newHeaderRows = [
        [t('export-csv:top_grossing_csv_title')],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:market'), currentMarketIso.toUpperCase()],
        [t('common:date'), new Date().toLocaleDateString()],
      ]

      setExportRows(newExportRows)
      setHeaderRows(newHeaderRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
      setHeaderRows([])
    }
  }, [isExportRequested, games, conventionalGenresMap, conventionalCategoriesMap, currentMarketIso, queryLimit, exportRows.length])

  return { headerRows, exportRows }
}

/**
 * Compare Games Page Feature Tab View Data Export Hook
 * @param rows Compare Feature Rows
 * @param gamesAndAnalysis Games and Analysis
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows for generateExport function
 */
export const useExportDataCompareGamesPageFeatureTabView = (rows: CompareFeatureRow[], gamesAndAnalysis: GameAndAnalysis[], isExportRequested: boolean) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])

  useEffect(() => {
    if (isExportRequested) {
      const newExportRows: ExportRow[] = []

      rows.forEach((row) => {
        let newDataRow = {}

        newDataRow = {
          ...newDataRow,
          ...{
            [t('common:feature')]: row.featureName,
            [t('common:feature_category')]: row.categoryName,
          },
        }

        gamesAndAnalysis.forEach(({ game, analysis }) => {
          const gameColumn = row.gameColumns[game.id]
          newDataRow = {
            ...newDataRow,
            ...{
              [`${game.resolvedName} / ${t('common:choice')}`]: gameColumn.choice,
              [`${game.resolvedName} / ${t('common:effect')}`]: Math.round(Math.max(gameColumn.gpsEffect, 0) * 100) / 100,
            },
          }
        })

        newExportRows.push(newDataRow)
      })

      setExportRows(newExportRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
    }
  }, [exportRows, gamesAndAnalysis, isExportRequested, rows])

  return { exportRows }
}

/**
 * Compare Games Performance Chart Data Export Hook
 * @param rollingGameEstimates Rolling Game Estimates
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows, header rows and filename for generateExport function
 */
export const useExportDataComparePerformanceChart = (estimates: GameEstimates[] | undefined, isExportRequested: boolean) => {
  const [headerRows, setHeaderRows] = useState<string[][]>([])
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const [filename, setFilename] = useState<string>('')

  const gameMarketPairs = useMemo(() => {
    return (
      estimates?.map((estimate) => ({
        appId: estimate.game.appId,
        markets: [estimate.marketIso],
      })) || []
    )
  }, [estimates])

  const { data: topGames } = useGetTopGamesMultiMarketQuery(
    { games: gameMarketPairs, include: ['id', 'appId', 'artist', 'name', 'dhistory', 'history', 'market'] },
    { skip: !gameMarketPairs.length }
  )

  useEffect(() => {
    if (estimates === undefined) return

    if (isExportRequested) {
      const gamesAppIdWithMarketIso: string[] = []
      const gamesNamesWithMarketIso: string[] = []

      estimates.forEach((gameEstimate) => {
        gamesAppIdWithMarketIso.push(`${gameEstimate.game.appId} (${gameEstimate.marketIso.toUpperCase()})`)
        gamesNamesWithMarketIso.push(`${gameEstimate.game.resolvedName} (${gameEstimate.marketIso.toUpperCase()})`)
      })

      const newHeaderRows = [
        [t('export-csv:game_update_history_chart_csv_title')],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:market'), gamesNamesWithMarketIso.join(', ')],
      ]

      const newExportRows: {}[] = []

      // get all unique dates from top games
      const allDates = topGames?.reduce((acc, game) => {
        game.history.forEach((historyItem) => {
          if (!acc.includes(historyItem?.ts)) {
            acc.push(historyItem?.ts)
          }
        })
        return acc
      }, [] as number[])

      // sort dates
      allDates?.sort((a, b) => a - b)

      // create export rows
      allDates?.forEach((date) => {
        const exportRow: { [key: string]: string } = {}
        const dateObj = new Date(date)
        exportRow[t('common:date')] = format(dateObj, 'd.M.yyyy')

        estimates.forEach((gameEstimate) => {
          const topGame = topGames?.find((game) => game.appId === gameEstimate.game.appId && game.market === gameEstimate.marketIso)
          const historyItem = topGame?.history.find((item) => isSameDay(dateObj, new Date(item.ts)))
          const dhistoryItem = topGame?.dhistory.find((item) => isSameDay(dateObj, new Date(item.ts)))
          const marketIso = gameEstimate.marketIso.toUpperCase()

          exportRow[`${gameEstimate.game.resolvedName} (${marketIso}) - ${t('common:grossing_rank')}`] =
            !historyItem?.rank || historyItem.rank > 500 ? '500+' : historyItem.rank.toString()
          exportRow[`${gameEstimate.game.resolvedName} (${marketIso}) - ${t('common:free_rank')}`] =
            !dhistoryItem?.rank || dhistoryItem.rank > 500 ? '500+' : dhistoryItem.rank.toString()

          const estimate: iosEstimate = gameEstimate.iosEstimates.find((estimate) => isSameDay(new Date(estimate.date), dateObj)) as iosEstimate

          exportRow[`${gameEstimate.game.resolvedName} (${marketIso}) - ${t('common:revenue_text')} $`] = estimate?.data.r ? estimate.data.r.toString() : ''
          exportRow[`${gameEstimate.game.resolvedName} (${marketIso}) - ${t('common:downloads_text')}`] = estimate?.data.d ? estimate.data.d.toString() : ''
        })

        newExportRows.push(exportRow)
      })

      setExportRows(newExportRows)
      setHeaderRows(newHeaderRows)
      setFilename(`GameRefinery_${gamesAppIdWithMarketIso.join('_')}_${t('common:apple_ios')}`)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
      setHeaderRows([])
      setFilename('')
    }
  }, [isExportRequested, estimates, exportRows.length, topGames])

  return { headerRows, exportRows, filename }
}

/**
 * Game Competitors Data Export Hook
 * @param multiGamesCompare Multi Games Compare
 * @param categories Categories
 * @param topCompetitorGames Top Competitor Games
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows for generateExport function
 */
export const useExportDataGameCompetitors = (
  multiGamesCompare: CompareMultiGames | undefined,
  categories: Category[] | undefined,
  topCompetitorGames: Game[] | undefined,
  isExportRequested: boolean
) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])

  useEffect(() => {
    if (isExportRequested && categories && multiGamesCompare && topCompetitorGames) {
      const newExportRows: {}[] = []

      const sortedCategories = categories
        .map((category) => {
          return { ...category, ...{ isOpen: false, features: [], ordr: category.ordr } } as ExportHelperCategory
        })
        .sort((a, b) => a.ordr - b.ordr)

      sortedCategories.forEach((category) => {
        category.features = multiGamesCompare.features.filter((feature) => feature.categoryId === category.id).sort((a, b) => a.order - b.order)
        category.features.forEach((feature) => {
          let newDataRow = {
            [t('common:feature')]: feature.featureName,
            [t('common:feature_category')]: category.name,
          }

          topCompetitorGames.forEach((game, index) => {
            newDataRow = {
              ...newDataRow,
              ...{
                [`${game.resolvedName} / ${t('common:choice')}`]: feature.choices[index],
                [`${game.resolvedName} / ${t('common:effect')}`]: feature.effects[index],
              },
            }
          })

          exportRows.push(newDataRow)
        })
      })

      setExportRows(newExportRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
    }
  }, [exportRows, categories, multiGamesCompare, topCompetitorGames, isExportRequested])

  return { exportRows }
}

export const useExportDataGameFeatures = (rows: GameFeatureTableRow[], isExportRequested: boolean) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])

  useEffect(() => {
    if (isExportRequested && rows) {
      const newExportRows: {}[] = []

      rows.forEach((row) => {
        const newDataRow = {
          [t('common:feature')]: row.featureName,
          [t('common:feature_group')]: row.featureGroupName,
          [t('common:feature_category')]: row.categoryName,
          [t('common:choice')]: row.choiceName,
          [t('common:effect')]: Math.max(row.gpsEffect, 0),
        }
        newExportRows.push(newDataRow)
      })

      setExportRows(newExportRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
    }
  }, [exportRows.length, isExportRequested, rows])

  return { exportRows }
}

/**
 * Game Revenue and Downloads Export Hook
 * @param estimates Game Estimates
 * @param phoneEstimates Phone Estimates
 * @param tabletEstimates Tablet Estimates
 * @param granularity Granularity
 * @param minDate Min Date
 * @param maxDate Max Date
 * @param game Game
 * @param isExportRequested Boolean to trigger export
 * @returns  Export rows, header rows and filename for generateExport function
 */
export const useExportDataGameRevenueAndDownloads = (
  estimates: MarketsPerformanceEstimates,
  phoneEstimates: MarketsPerformanceEstimates,
  tabletEstimates: MarketsPerformanceEstimates,
  granularity: GranularityValue,
  minDate: Date,
  maxDate: Date,
  game: Game,
  isExportRequested: boolean,
  rollingDays: number,
  dateRange: DateRangeValue | undefined
) => {
  const [headerRows, setHeaderRows] = useState<string[][]>([])
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const [filename, setFilename] = useState<string>('')
  const dateFormat = 'yyyy/MM/dd'

  useEffect(() => {
    if (isExportRequested) {
      const totalMarket = estimates.total
      const newExportRows: {}[] = []

      const selectedMinDate = dateRange && dateRange.fromDate && new Date(dateRange.fromDate.getTime())
      const selectedMaxDate = dateRange && dateRange.toDate && new Date(dateRange.toDate.getTime())
      const selectedDateRange = new Date(maxDate.getTime() - rollingDays * 24 * 60 * 60 * 1000)

      const formattedDateStart = rollingDays > 1 ? format(selectedDateRange, dateFormat) : format(selectedMinDate || minDate, dateFormat)
      const formattedDateEnd = rollingDays && selectedMaxDate ? format(selectedMaxDate, dateFormat) : format(maxDate, dateFormat)

      const newHeaderRows = [
        [t('export-csv:game_revenue_downloads_csv_title')],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:date_start'), formattedDateStart],
        [t('common:date_end'), formattedDateEnd],
        [t('common:revenue_text') + ' $', totalMarket?.revenue?.toString() || ''],
        [t('common:downloads_text'), totalMarket?.downloads?.toString() || ''],
        [t('common:date_granularity'), granularity],
      ]

      let dateFormatStr: string
      switch (granularity) {
        default:
        case GranularityValue.Day:
          dateFormatStr = 'yyyy/MM/dd'
          break
        case GranularityValue.Month:
          dateFormatStr = 'yyyy/MM'
          break
        case GranularityValue.Week:
          dateFormatStr = 'yyyy/w'
          break
      }

      phoneEstimates.markets.forEach((market) => {
        phoneEstimates.estimates.forEach((phoneEstimate) => {
          const tabletEstimate: PerformanceEstimate | undefined = tabletEstimates.estimates.find((estimate) => estimate.ts === phoneEstimate.ts)

          const newDataRow = {
            'App ID': game.appId,
            [t('common:name')]: game.resolvedName,
            [t('common:publisher')]: game.artist,
            [t('common:country')]: market.marketIso.toUpperCase(),
            [t('common:date')]: format(phoneEstimate.ts, dateFormatStr, { weekStartsOn: 0 }),
            [t('common:phone_revenue') + ' $']: phoneEstimate.revenue,
            [t('common:phone_downloads')]: phoneEstimate.downloads,
            [t('common:tablet_revenue') + ' $']: tabletEstimate?.revenue,
            [t('common:tablet_downloads')]: tabletEstimate?.downloads,
          }
          newExportRows.push(newDataRow)
        })
      })

      setExportRows(newExportRows)
      setHeaderRows(newHeaderRows)
      setFilename(`GameRefinery_${game.appId}_${t('common:apple_ios')}_${formattedDateStart}_${formattedDateEnd}_${granularity}`)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
      setHeaderRows([])
      setFilename('')
    }
  }, [
    dateRange,
    estimates.total,
    exportRows.length,
    game.appId,
    game.artist,
    game.resolvedName,
    granularity,
    isExportRequested,
    maxDate,
    minDate,
    phoneEstimates.estimates,
    phoneEstimates.markets,
    rollingDays,
    tabletEstimates.estimates,
  ])

  return { headerRows, exportRows, filename }
}

/**
 * Game Update History Export Hook
 * @param game Game
 * @param updateImpactsMappedForTable Game Update Impacts rows
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows and filename for generateExport function
 */
export const useExportDataGameUpdateHistory = (game: Game, updateImpactsMappedForTable: TableRowUpdateImpact[], isExportRequested: boolean) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const [filename, setFilename] = useState<string>('')
  const { map: conventionalSubgenresMap } = useConventionalSubgenresMap()
  const dateFormatter = useDateTimeFormatter()

  useEffect(() => {
    if (isExportRequested) {
      const newExportRows: {}[] = []
      const versionTags = languageService.getVersionTags()

      // filter updates first by UPDATE_IMPACTS_SINCE and then sort update impacts by release date
      const sortedUpdateImpactsMappedForTable = updateImpactsMappedForTable
        .slice()
        .filter((updateImpact) => {
          return updateImpact.releaseDate >= UPDATE_IMPACTS_SINCE
        })
        .sort((a, b) => {
          return b.releaseDate - a.releaseDate
        })

      const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000
      const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000
      const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000

      sortedUpdateImpactsMappedForTable.forEach((gameUpdateImpact) => {
        const conventionalSubgenre = conventionalSubgenresMap[gameUpdateImpact.conventionalSubgenreId]
        let newDataRow = {}

        newDataRow = {
          ...newDataRow,
          ...{
            'App Id': game.appId,
            [t('common:game')]: gameUpdateImpact.gameName,
            [t('common:publisher')]: gameUpdateImpact.gameArtist,
            [t('common:conventional_subgenre')]: gameUpdateImpact.conventionalSubgenre,
            [t('common:conventional_genre')]: conventionalSubgenre.genre.name,
            [t('common:conventional_category')]: conventionalSubgenre.category.name,
            [t('common:version_date')]: format(gameUpdateImpact.releaseDate, 'MMM dd, yyyy'),
            [t('common:version')]: gameUpdateImpact.version,
            [t('common:days_since_global_launch')]: gameUpdateImpact.daysSinceReleased,
          },
        }

        versionTags.forEach((versionTag) => {
          const versionTagFound = gameUpdateImpact.versionTags.find((tag) => tag.id === versionTag.id) ? true : false
          newDataRow = {
            ...newDataRow,
            ...{
              [t('common:update_tag') + ` - ${versionTag.name.toUpperCase()}`]: versionTagFound ? t('common:yes') : t('common:no'),
            },
          }
        })

        newDataRow = {
          ...newDataRow,
          ...{
            [t('common:game_power_score')]: gameUpdateImpact.gps,
            [t('common:game_power_score') + ` - ${t('common:change_text')}`]: gameUpdateImpact.gpsChange,
          },
        }

        const days7Aggregates = new UpdateImpactAggregates(gameUpdateImpact.aggregates, ComparisonInterval.DAYS_7)
        const days14Aggregates = new UpdateImpactAggregates(gameUpdateImpact.aggregates, ComparisonInterval.DAYS_14)
        const days30Aggregates = new UpdateImpactAggregates(gameUpdateImpact.aggregates, ComparisonInterval.DAYS_30)
        const currentTime = Date.now()

        // Revenue
        const days7RevPrefix = `${t('common:revenue_text')} / ${t('common:7_days')} /`
        const days14RevPrefix = `${t('common:revenue_text')} / ${t('common:14_days')} /`
        const days30RevPrefix = `${t('common:revenue_text')} / ${t('common:30_days')} /`
        newDataRow = {
          ...newDataRow,
          ...{
            // 7 days
            [`${days7RevPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? `${days7Aggregates.revenueTrend * 100}%` : 'N/A',
            [`${days7RevPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? days7Aggregates.revenueImpact : 'N/A',
            [`${days7RevPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.baseWeek1Revenue : 'N/A',

            // 14 days
            [`${days14RevPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? `${days14Aggregates.revenueTrend * 100}%` : 'N/A',
            [`${days14RevPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? days14Aggregates.revenueImpact : 'N/A',
            [`${days14RevPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.baseWeek2Revenue : 'N/A',

            // 30 days
            [`${days30RevPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? `${days30Aggregates.revenueTrend * 100}%` : 'N/A',
            [`${days30RevPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? days30Aggregates.revenueImpact : 'N/A',
            [`${days30RevPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.month1Revenue : 'N/A',
          },
        }

        // Downloads
        const days7DLPrefix = `${t('common:downloads_text')} / ${t('common:7_days')} /`
        const days14DLPrefix = `${t('common:downloads_text')} / ${t('common:14_days')} /`
        const days30DLPrefix = `${t('common:downloads_text')} / ${t('common:30_days')} /`
        newDataRow = {
          ...newDataRow,
          ...{
            // 7 days
            [`${days7DLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? `${days7Aggregates.downloadsTrend * 100}%` : 'N/A',
            [`${days7DLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? days7Aggregates.downloadsImpact : 'N/A',
            [`${days7DLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.baseWeek1Downloads : 'N/A',

            // 14 days
            [`${days14DLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? `${days14Aggregates.downloadsTrend * 100}%` : 'N/A',
            [`${days14DLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? days14Aggregates.downloadsImpact : 'N/A',
            [`${days14DLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.baseWeek2Downloads : 'N/A',

            // 30 days
            [`${days30DLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? `${days30Aggregates.downloadsTrend * 100}%` : 'N/A',
            [`${days30DLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? days30Aggregates.downloadsImpact : 'N/A',
            [`${days30DLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? gameUpdateImpact.aggregates?.month1Downloads : 'N/A',
          },
        }

        // REV/DL
        const days7RevDLPrefix = `${t('common:revenue_downloads_ratio_shorthand')} / ${t('common:7_days')} /`
        const days14RevDLPrefix = `${t('common:revenue_downloads_ratio_shorthand')} / ${t('common:14_days')} /`
        const days30RevDLPrefix = `${t('common:revenue_downloads_ratio_shorthand')} / ${t('common:30_days')} /`
        newDataRow = {
          ...newDataRow,
          ...{
            // 7 days
            [`${days7RevDLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? `${days7Aggregates.revenueAndDownloadsRatioTrend * 100}%` : 'N/A',
            [`${days7RevDLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? days7Aggregates.revenueAndDownloadsRatioImpact : 'N/A',
            [`${days7RevDLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + sevenDaysInMillis < currentTime ? days7Aggregates.revenueAndDownloadsRatio : 'N/A',

            // 14 days
            [`${days14RevDLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? `${days14Aggregates.revenueAndDownloadsRatioTrend * 100}%` : 'N/A',
            [`${days14RevDLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? days14Aggregates.revenueAndDownloadsRatioImpact : 'N/A',
            [`${days14RevDLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + fourteenDaysInMillis < currentTime ? days14Aggregates.revenueAndDownloadsRatio : 'N/A',

            // 30 days
            [`${days30RevDLPrefix} ${t('common:change_percent_text')}`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? `${days30Aggregates.revenueAndDownloadsRatioTrend * 100}%` : 'N/A',
            [`${days30RevDLPrefix} ${t('common:value_change_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? days30Aggregates.revenueAndDownloadsRatioImpact : 'N/A',
            [`${days30RevDLPrefix} ${t('common:total_value_text')} $`]:
              gameUpdateImpact.releaseDate + thirtyDaysInMillis < currentTime ? days30Aggregates.revenueAndDownloadsRatio : 'N/A',
          },
        }

        newDataRow = {
          ...newDataRow,
          ...{
            [t('common:release_notes')]: gameUpdateImpact.releaseNote,
          },
        }

        let allChanges: string = ''
        let allChangesRows = {}
        let allUpdates: string = ''
        let allUpdatesRows = {}

        if (gameUpdateImpact.changedFeatures && gameUpdateImpact.changedFeatures.length > 0) {
          gameUpdateImpact.changedFeatures.forEach((changedFeature) => {
            const featurePrefix = `${t('common:feature')} ${changedFeature.featureLegacyId}`
            allChanges += `${changedFeature.featureName}: ${changedFeature.choice1}\n`

            allChangesRows = {
              ...allChangesRows,
              ...{
                [featurePrefix]: changedFeature.featureName,
                [`${featurePrefix} - ${t('common:old_choice_text')}`]: changedFeature.choice2,
                [`${featurePrefix} - ${t('common:new_choice_text')}`]: changedFeature.choice1,
              },
            }
          })
        } else {
          allChangesRows = {
            ...newDataRow,
            ...{
              [t('common:feature_changes')]: '',
            },
          }
        }

        if (gameUpdateImpact.updatedFeatures && gameUpdateImpact.updatedFeatures.length > 0) {
          gameUpdateImpact.updatedFeatures.forEach((updatedFeature) => {
            const featurePrefix = `${t('common:feature')} ${updatedFeature.featureLegacyId}`
            allUpdates += `${updatedFeature.featureName}: ${updatedFeature.choiceName}\n`
            allUpdatesRows = {
              ...allUpdatesRows,
              ...{
                [featurePrefix]: updatedFeature.featureName,
                [`${featurePrefix} - ${t('common:choice')}`]: updatedFeature.choiceName,
              },
            }
          })
        } else {
          allUpdatesRows = {
            ...newDataRow,
            ...{
              [t('common:updated_features')]: '',
            },
          }
        }

        newDataRow = {
          ...newDataRow,
          ...{
            [t('common:feature_changes')]: allChanges,
          },
          ...{
            [t('common:updated_features')]: allUpdates,
          },
          ...allChangesRows,
          ...allUpdatesRows,
        }

        newExportRows.push(newDataRow)
      })

      setExportRows(newExportRows)
      setFilename(`${game.appId}-game-update-impacts`)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
      setFilename('')
    }
  }, [game, updateImpactsMappedForTable, isExportRequested, conventionalSubgenresMap, exportRows.length, dateFormatter])

  return { exportRows, filename }
}

/**
 * Performance chart export data hook
 * @param game Top game
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows and filename
 */
export const useExportDataPerformanceChart = (game: TopGame | undefined, estimates: PerformanceEstimate[], isExportRequested: boolean) => {
  const [headerRows, setHeaderRows] = useState<string[][]>([])
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const [filename, setFilename] = useState<string>('')
  const { currentMarketIso } = useCurrentMarket()

  useEffect(() => {
    if (game && isExportRequested) {
      let newHeaderRows = [
        [t('export-csv:game_update_history_chart_csv_title')],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:market'), game?.market.toUpperCase()],
      ]

      // get all unqiue datse from history and dhistory
      const allDates = new Set([
        ...game.history.map((historyItem) => format(new Date(historyItem.ts), 'MM-dd-yyyy')),
        ...game.dhistory.map((dhistoryItem) => format(new Date(dhistoryItem.ts), 'MM-dd-yyyy')),
      ])

      // Convert Set to array
      let allDatesArray: string[] = Array.from(allDates)

      // Sort the array of dates
      allDatesArray.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

      // Create a new Set from the sorted array
      const sortedDatesSet: Set<string> = new Set(allDatesArray)

      const dhistoryMap = new Map(game.dhistory.map((dhistoryItem) => [format(new Date(dhistoryItem.ts), 'MM-dd-yyyy'), dhistoryItem]))
      const historyMap = new Map(game.history.map((historyItem) => [format(new Date(historyItem.ts), 'MM-dd-yyyy'), historyItem]))

      let newExportRows: {}[] = []

      sortedDatesSet.forEach((item) => {
        const historyItem = historyMap.get(item)
        const dhistoryItem = dhistoryMap.get(item)

        let newRow = {
          [t('common:date')]: format(new Date(item), 'dd.MM.yyyy'),
          [t('common:grossing_rank')]: !historyItem?.rank || historyItem.rank > 500 ? '500+' : historyItem.rank,
          [t('common:free_rank')]: !dhistoryItem || !dhistoryItem.rank || dhistoryItem.rank > 500 ? '500+' : dhistoryItem?.rank,
        }

        if (estimates) {
          const ESTIMATE_DATA = estimates.find((estimate) => isSameDay(estimate.ts, new Date(item)))
          newRow = {
            ...newRow,
            [t('common:revenue_text') + ' $']: ESTIMATE_DATA ? ESTIMATE_DATA.revenue : '',
            [t('common:downloads_text')]: ESTIMATE_DATA ? ESTIMATE_DATA.downloads : '',
          }
        }

        newExportRows.push(newRow)
      })

      // if there are no sortedDatesSet, but there is estimates data then loop through and add them to the export rows
      // this may happen with soft launch games that do not have history data
      if (sortedDatesSet.size === 0 && estimates) {
        estimates.forEach((estimate) => {
          newExportRows.push({
            [t('common:date')]: format(new Date(estimate.ts), 'dd.MM.yyyy'),
            [t('common:grossing_rank')]: '',
            [t('common:free_rank')]: '',
            [t('common:revenue_text') + ' $']: estimate.revenue,
            [t('common:downloads_text')]: estimate.downloads,
          })
        })
      }

      setExportRows(newExportRows)
      setHeaderRows(newHeaderRows)
      setFilename(`GameRefinery_${game.appId}_${t('common:apple_ios')}_${game.market.toUpperCase()}`)
    }

    if ((!isExportRequested && exportRows.length > 0) || !game) {
      setExportRows([])
      setHeaderRows([])
      setFilename('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, exportRows.length, isExportRequested, currentMarketIso])

  return { exportRows, headerRows, filename }
}

/**
 * Export data hook for the Performance chart V2
 */
type ExportDataPerformanceChartV2HookParams = {
  appId: number
  marketIso: string
  granularity: GranularityValue
  dateRange?: DateRangeValue
  data: ReturnType<typeof useGameUpdatePerformanceData>
  excludedDataTypes?: PerformanceChartV2DataType[]
}
export const useExportDataPerformanceChartV2 = (
  { appId, marketIso, granularity, dateRange, data, excludedDataTypes }: ExportDataPerformanceChartV2HookParams,
  options?: { skip?: boolean }
) =>
  useMemo(() => {
    if (!options?.skip && data) {
      const dateFormat = 'yyyy/MM/dd'
      const fromDate = dateRange?.fromDate || 0
      const toDate = dateRange?.toDate || 0

      const allDates = new Set(
        [
          ...data.estimates.map((estimate) => estimate.ts),
          ...data.topGrossingRanks.map((rank) => rank.ts),
          ...data.freeRanks.map((rank) => rank.ts),
          ...data.dailyActiveUsers.map((dau) => dau.date),
          ...data.monthlyActiveUsers.map((mau) => mau.date),
        ].map((date) => +adjustDateByGranularity(date, granularity))
      )

      const sortedDatesInDateRange = Array.from(allDates)
        .filter((ts) => isWithinInterval(ts, { start: fromDate, end: toDate }))
        .sort()

      const headerRows = [
        [t('export-csv:game_update_history_chart_csv_title')],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:market'), marketIso.toUpperCase()],
        [t('common:date_start'), format(fromDate, dateFormat)],
        [t('common:date_end'), format(toDate, dateFormat)],
        [t('common:date_granularity'), granularity],
      ]

      const exportRows =
        sortedDatesInDateRange.map((date) => {
          const formattedDate = formatDateByGranularity(date, granularity)
          const granularityChecker = isInSameGranularityPeriod(granularity)
          const estimate = data.estimates.find((estimate) => granularityChecker(estimate.ts, date))
          const topGrossingRank = data.topGrossingRanks.find((rank) => granularityChecker(rank.ts, date))
          const freeRank = data.freeRanks.find((rank) => granularityChecker(rank.ts, date))
          const dau = data.dailyActiveUsers.find((dau) => granularityChecker(dau.date, date))
          const mau = data.monthlyActiveUsers.find((mau) => granularityChecker(mau.date, date))
          const arpdau = data.averageRevenuePerDailyActiveUser.find((aprdau) => granularityChecker(aprdau.date, date))

          const newRow: Record<string, string | number> = {
            [t('common:date')]: formattedDate,
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.Ranks)) {
            newRow[t('common:grossing_rank')] = !topGrossingRank?.rank || topGrossingRank?.rank > 500 ? '500+' : Math.round(topGrossingRank?.rank)
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.Ranks)) {
            newRow[t('common:free_rank')] = !freeRank?.rank || freeRank?.rank > 500 ? '500+' : Math.round(freeRank?.rank)
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.Revenue)) {
            newRow[t('common:revenue_text') + ' $'] = estimate?.revenue || ''
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.Downloads)) {
            newRow[t('common:downloads_text')] = estimate?.downloads || ''
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.DAU)) {
            newRow[t('common:dau')] = dau?.activeUsers ? Math.round(dau?.activeUsers) : ''
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.MAU)) {
            newRow[t('common:mau')] = mau?.activeUsers ? Math.round(mau?.activeUsers) : ''
          }

          if (!excludedDataTypes?.includes(PerformanceChartV2DataType.ARPDAU)) {
            newRow[t('live-events:average_revenue_per_daily_active_user') + ' $'] = arpdau?.value ? (Math.round(arpdau?.value * 100) / 100).toFixed(2) : ''
          }

          return newRow
        }) || []

      const filename = `GameRefinery_${appId}_${t('common:apple_ios')}_${marketIso.toUpperCase()}_${format(fromDate, dateFormat)}_${format(
        toDate,
        dateFormat
      )}_${granularity}`

      return { headerRows, exportRows, filename }
    }
  }, [appId, data, dateRange?.fromDate, dateRange?.toDate, granularity, marketIso, options?.skip, excludedDataTypes])

/**
 * Export data hook for the Visual Explorer page
 * @param attributeModels Attribute models
 * @param gameData Game data
 * @param ranks Game ranks
 * @param attributes Attributes
 * @param isExportRequested Boolean to trigger export
 * @returns Export rows for generateExport function
 */
export const useExportDataVisualExplorerOverview = (
  attributeModels: VisualAnalysisModel[],
  gameData: VisualAnalysis[] | undefined,
  ranks:
    | {
        [key: number]: number
      }
    | undefined,
  attributes: AttributeModelNames | undefined,
  isExportRequested: boolean
) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])

  useEffect(() => {
    if (isExportRequested && attributeModels && gameData && ranks && attributes) {
      const newExportRows = getAllTopCounts(attributeModels, gameData, ranks, attributes).map((row) => ({
        [t('visuals-explorer:icon_attribute_text')]: row.name,
        [t('visuals-explorer:attribute_type')]: t(visualsTranslationMap[row.model]),
        [t('common:top_50')]: row.top50,
        [t('common:outside_top_50')]: row.out50,
        [`${t('common:difference')} / ${t('common:top_50')}`]: row.diff50,
        [t('common:top_100')]: row.top100,
        [t('common:outside_top_100')]: row.out100,
        [`${t('common:difference')} / ${t('common:top_100')}`]: row.diff100,
        [t('common:top_200')]: row.top200,
        [t('common:outside_top_200')]: row.out200,
        [`${t('common:difference')} / ${t('common:top_200')}`]: row.diff200,
        [t('common:top_500')]: row.top500,
        [t('common:outside_top_500')]: row.out500,
        [`${t('common:difference')} / ${t('common:top_500')}`]: row.diff500,
        [t('common:average_rank')]: row.averageRank || '-',
        [t('common:median_rank')]: row.medianRank || '-',
      }))

      setExportRows(newExportRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
    }
  }, [attributeModels, gameData, ranks, attributes, isExportRequested, exportRows.length])

  return { exportRows }
}

/**
 * Market Share page export data hook
 * @param currentSubpage Current subpage
 * @param marketShareData Market share data
 * @param quarters Quarter data
 * @param marketShareScopeType Market Share scope type
 * @param topGames Top Games data
 * @param isExportRequested  Boolean to trigger export
 * @returns Export rows and filename
 */
export const useExportDataMarketSharePage = (
  currentSubpage: MarketShareSubpage | undefined,
  marketShareData: {
    topGamesWithGameQuarters: TopGame[]
    calculatedQuarters: Quarter[]
    marketShares: MarketShare[]
  } | null,
  quarters: Quarter[] | undefined,
  marketShareScopeType: MarketShareScopeType,
  topGames: TopGame[] | undefined,
  isExportRequested: boolean
) => {
  const [headerRows, setHeaderRows] = useState<string[][]>([])
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const [filename, setFilename] = useState<string>('')
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { map: conventionalSubgenresMap } = useConventionalSubgenresMap()

  useEffect(() => {
    if (isExportRequested) {
      let newHeaderRows = []
      let newExportRows: {}[] = []
      // sort quarters descending on startTimestamp
      const sortedQuarters = quarters?.slice().sort((a, b) => b.startTimestamp - a.startTimestamp) || []

      const genereateMarketShareQuartersDataExportRows = () => {
        if (!marketShareData?.marketShares.length) {
          return [{}]
        }

        const exportRows: {}[] = []
        const sortedMarketShareData = marketShareData.marketShares.slice().sort((a, b) => nameComparator(a.name, b.name))

        const valueObjects = [
          {
            id: 'revenuePercentage',
            name: t('common:revenue_text') + ' %',
          },
          {
            id: 'downloadsPercentage',
            name: t('common:downloads_text') + ' %',
          },
          {
            id: 'revenueTop500Value',
            name: t('common:revenue_text') + ' $',
          },
          {
            id: 'downloadsTop500Value',
            name: t('common:downloads_text'),
          },
        ]

        switch (marketShareScopeType.id) {
          case 'subgenre':
          case 'genre':
          case 'category': {
            sortedMarketShareData.forEach((marketShare) => {
              const marketShareName = marketShare.name
              let genreName: string
              let categoryName: string

              switch (marketShareScopeType.id) {
                case 'subgenre':
                  genreName = conventionalSubgenresMap[marketShare.id]?.genre?.name || ''
                  categoryName = conventionalSubgenresMap[marketShare.id]?.category?.name || ''
                  break

                default:
                  break
              }

              sortedQuarters?.forEach((quarter) => {
                valueObjects.forEach((valueObject) => {
                  let newDataRow = {}

                  switch (marketShareScopeType.id) {
                    case 'subgenre': {
                      newDataRow = {
                        ...newDataRow,
                        ...{
                          [t('common:conventional_subgenre')]: marketShareName,
                          [t('common:conventional_genre')]: genreName,
                          [t('common:conventional_category')]: categoryName,
                        },
                      }
                      break
                    }
                    case 'genre': {
                      newDataRow = {
                        ...newDataRow,
                        ...{
                          [t('common:conventional_genre')]: marketShareName,
                        },
                      }
                      break
                    }
                    case 'category': {
                      newDataRow = {
                        ...newDataRow,
                        ...{
                          [t('common:conventional_category')]: marketShareName,
                        },
                      }
                      break
                    }
                  }
                  newDataRow = {
                    ...newDataRow,
                    ...{
                      [t('common:quarter_text')]: quarter.name,
                      [t('common:type_text')]: valueObject.name,
                    },
                  }

                  let value
                  switch (valueObject.id) {
                    case 'revenuePercentage': {
                      value = marketShare.quarters[quarter.quarterIdentifier].revenuePercentage * 100
                      break
                    }
                    case 'downloadsPercentage': {
                      value = marketShare.quarters[quarter.quarterIdentifier].downloadsPercentage * 100
                      break
                    }
                    case 'revenueTop500Value': {
                      value = marketShare.quarters[quarter.quarterIdentifier].revenueTop500Value
                      break
                    }
                    case 'downloadsTop500Value': {
                      value = marketShare.quarters[quarter.quarterIdentifier].downloadsTop500Value
                      break
                    }
                  }

                  newDataRow = {
                    ...newDataRow,
                    ...{
                      [t('common:share_text')]: value,
                    },
                  }

                  exportRows.push(newDataRow)
                })
              })
            })
            break
          }
          case 'publisher': {
            sortedMarketShareData.forEach((marketShare) => {
              const publisherName = marketShare.name
              quarters?.forEach((quarter) => {
                valueObjects.forEach((valueObject) => {
                  let newDataRow: {} = {
                    [t('common:publisher')]: publisherName,
                    [t('common:quarter_text')]: quarter.name,
                    [t('common:type_text')]: valueObject.name,
                  }
                  let value
                  switch (valueObject.id) {
                    case 'revenuePercentage': {
                      value = marketShare.quarters[quarter.quarterIdentifier].revenuePercentage * 100
                      break
                    }
                    case 'downloadsPercentage': {
                      value = marketShare.quarters[quarter.quarterIdentifier].downloadsPercentage * 100
                      break
                    }
                    case 'revenueTop500Value': {
                      value = marketShare.quarters[quarter.quarterIdentifier].revenueTop500Value
                      break
                    }
                    case 'downloadsTop500Value': {
                      value = marketShare.quarters[quarter.quarterIdentifier].downloadsTop500Value
                      break
                    }
                  }

                  newDataRow = {
                    ...newDataRow,
                    ...{
                      [t('common:share_text')]: value,
                    },
                  }

                  exportRows.push(newDataRow)
                })
              })
            })
            break
          }
        }
        return exportRows
      }

      const genreateMarketShareGamesDataExportRows = () => {
        if (!marketShareData?.marketShares.length) {
          return [{}]
        }

        const exportRows: {}[] = []
        const valueObjects = [
          {
            id: 'revenuePercentage',
            name: t('common:revenue_text') + ' %',
          },
          {
            id: 'downloadsPercentage',
            name: t('common:downloads_text') + ' %',
          },
          {
            id: 'revenueValue',
            name: t('common:revenue_text') + ' $',
          },
          {
            id: 'downloadsValue',
            name: t('common:downloads_text'),
          },
        ]

        const filteredSortedQuarters = sortedQuarters.map((quarter) => {
          return {
            ...quarter,
            revenuesAndDownloads: Object.entries(quarter.revenuesAndDownloads)
              .filter(([key, { revenue, downloads }]) => revenue !== 0 || downloads !== 0)
              .reduce((acc, [key, entry]) => {
                acc[key] = entry
                return acc
              }, {} as { [key: string]: { revenue: number; downloads: number } }),
          }
        })

        topGames?.forEach((game) => {
          if (game.conventionalSubgenreId) {
            const subgenreName = game.conventionalSubgenre
            const genreName = conventionalSubgenresMap[game.conventionalSubgenreId]?.genre?.name
            const categoryName = conventionalSubgenresMap[game.conventionalSubgenreId]?.category?.name
            filteredSortedQuarters?.forEach((quarter) => {
              valueObjects.forEach((valueObject) => {
                let value
                switch (valueObject.id) {
                  case 'revenuePercentage': {
                    value = game.marketLevelQuarters[quarter.quarterIdentifier as keyof typeof quarter].revenuePercentage * 100
                    break
                  }
                  case 'downloadsPercentage': {
                    value = game.marketLevelQuarters[quarter.quarterIdentifier as keyof typeof quarter].downloadsPercentage * 100
                    break
                  }
                  case 'revenueValue': {
                    value = game.marketLevelQuarters[quarter.quarterIdentifier as keyof typeof quarter].revenueTop500Value
                    break
                  }
                  case 'downloadsValue': {
                    value = game.marketLevelQuarters[quarter.quarterIdentifier as keyof typeof quarter].downloadsTop500Value
                    break
                  }
                }

                if (value === 0 && game.marketLevelQuarters[quarter.quarterIdentifier as keyof typeof quarter].revenueTop500Value === 0) {
                  return
                }

                let newDataRow = {}

                newDataRow = {
                  ...newDataRow,
                  ...{
                    'App ID': game.appId,
                    [t('common:game')]: game.name,
                    [t('common:publisher')]: game.artist,
                    [t('common:conventional_subgenre')]: subgenreName,
                    [t('common:conventional_genre')]: genreName,
                    [t('common:conventional_category')]: categoryName,
                    [t('common:quarter_text')]: quarter.name,
                    [t('common:type_text')]: valueObject.name,
                  },
                }

                newDataRow = {
                  ...newDataRow,
                  ...{
                    [t('common:share_text')]: value,
                  },
                }
                exportRows.push(newDataRow)
              })
            })
          }
        })

        return exportRows
      }

      switch (currentSubpage?.id) {
        case 'quarter-comparison': {
          newHeaderRows.push([t('export-csv:market_share_quarter_comparison_csv_title')])
          newExportRows = genereateMarketShareQuartersDataExportRows()
          setFilename(
            `GameRefinery_Market_Share_Quarter_Comparison_${t('common:apple_ios')}_${mainMarketIso.toUpperCase()}_${format(new Date(), 'yyyy-MM-dd')}`
          )
          break
        }
        case 'top-500-games': {
          newHeaderRows.push([t('export-csv:market_share_games_csv_title')])
          newExportRows = genreateMarketShareGamesDataExportRows()
          setFilename(`GameRefinery_Market_Share_Games_${t('common:apple_ios')}_${mainMarketIso.toUpperCase()}_${format(new Date(), 'yyyy-MM-dd')}`)
          break
        }
        default:
          throw new Error('Invalid export subpage for data export')
      }

      newHeaderRows = newHeaderRows.concat([
        [t('common:data'), marketShareScopeType.id.charAt(0).toUpperCase() + marketShareScopeType.id.slice(1)],
        [t('common:platform'), t('common:apple_ios')],
        [t('common:market'), mainMarketIso.toUpperCase()],
        [t('common:date'), format(new Date(), 'yyyy/MM/dd')],
      ])

      setExportRows(newExportRows)
      setHeaderRows(newHeaderRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
      setHeaderRows([])
      setFilename('')
    }
  }, [
    currentSubpage,
    isExportRequested,
    exportRows.length,
    mainMarketIso,
    marketShareData?.marketShares,
    marketShareScopeType.id,
    conventionalSubgenresMap,
    quarters,
    topGames,
  ])

  return { exportRows, headerRows, filename }
}

/**
 * Market Explorer Features Export
 * @param segmentConfigurations Segment configurations
 * @param rows Data rows
 * @param isExportRequested Boolean to trigger export
 * @param singleSegmentIndex Single segment index
 * @returns Segment export rows in a table format
 */
export const useExportDataMarketExplorerFeatures = (
  segmentConfigurations: MarketExplorerSegmentConfiguration[],
  rows: FeatureDataRow[],
  isExportRequested: boolean,
  singleSegmentIndex?: number
) => {
  const [segmentExportRows, setSegmentExportRows] = useState<ExportRow[][]>([])
  const hasAccessToMotivations = useMotivationsAccessCheck()

  useEffect(() => {
    if (isExportRequested) {
      const getDataRows = (segmentConfiguration: MarketExplorerSegmentConfiguration, segmentRows: FeatureDataRow[]) => {
        const exportRows: {}[] = []
        const motivationTypes = motivationService.getMotivationTypes()
        const keyMotivations = motivationService.getKeyMotivations()
        segmentRows.forEach((row) => {
          let newDataRow = {}
          const FULL_POPULARITY_DATA_AVAILABLE = featureService.fullFeaturePopularityDataAvailableForMarketIsoAndCategoryId(row.marketIso, row.featureId)
          newDataRow = {
            ...newDataRow,
            ...{
              [t('common:feature')]: row.featureLabel,
              [t('common:choice')]: row.choiceLabel,
              [t('common:feature_effect_games_in_segment')]: Math.max(row.avgEffect || 0, 0),
              [t('common:overall_popularity')]: FULL_POPULARITY_DATA_AVAILABLE ? (row.Overall || 0) + '%' : t('common:not_available_shorthand'),
              [t('common:top_20_popularity_percent')]: FULL_POPULARITY_DATA_AVAILABLE ? (row['20Percent'] || 0) + '%' : t('common:not_available_shorthand'),
              [t('common:top_50_popularity_percent')]: FULL_POPULARITY_DATA_AVAILABLE ? (row['50Percent'] || 0) + '%' : t('common:not_available_shorthand'),
              [t('common:top_20_popularity_difference')]: FULL_POPULARITY_DATA_AVAILABLE ? row.difference20Percent || 0 : t('common:not_available_shorthand'),
              [t('common:top_50_popularity_difference')]: FULL_POPULARITY_DATA_AVAILABLE ? row.difference50Percent || 0 : t('common:not_available_shorthand'),
              [t('common:male')]: row.demographicsMale ? row.demographicsMale * 100 + '%' : t('common:not_available_shorthand'),
              [t('common:female')]: row.demographicsFemale ? row.demographicsFemale * 100 + '%' : t('common:not_available_shorthand'),
              [t('common:age16_24')]: row.demographicsAge16_24 ? row.demographicsAge16_24 * 100 + '%' : t('common:not_available_shorthand'),
              [t('common:age25_44')]: row.demographicsAge25_44 ? row.demographicsAge25_44 * 100 + '%' : t('common:not_available_shorthand'),
              [t('common:age45_plus')]: row.demographicsAge45 ? row.demographicsAge45 * 100 + '%' : t('common:not_available_shorthand'),
            },
          }
          if (hasAccessToMotivations) {
            motivationTypes.forEach((motivationType) => {
              newDataRow = {
                ...newDataRow,
                ...{
                  [`${t('motivations:motivations')} | ${motivationService.getSegmentName(motivationType)}`]: row?.motivation?.[motivationType]
                    ? row.motivation[motivationType]
                    : t('common:not_available_shorthand'),
                },
              }
            })
            keyMotivations.forEach((keyMotivation) => {
              newDataRow = {
                ...newDataRow,
                ...{
                  [`${t('motivations:player_archetypes')} | ${motivationService.getSegmentName(keyMotivation)}`]: row?.archetypes?.[keyMotivation]
                    ? `${row.archetypes[keyMotivation] * 100} %`
                    : t('common:not_available_shorthand'),
                },
              }
            })
          }
          exportRows.push(newDataRow)
        })
        return exportRows
      }

      const newSegmenetExportRows: ExportRow[][] = []

      if (singleSegmentIndex !== undefined) {
        const exportRows = getDataRows(
          segmentConfigurations[0],
          rows.filter((row) => row.segmentGroup === singleSegmentIndex + 1)
        )
        newSegmenetExportRows.push(exportRows)
      } else {
        segmentConfigurations.forEach((segmentConfiguration, index) => {
          const exportRows = getDataRows(
            segmentConfiguration,
            rows.filter((row) => row.segmentGroup === index + 1)
          )

          newSegmenetExportRows.push(exportRows)
        })
      }

      setSegmentExportRows(newSegmenetExportRows)
    }

    if (!isExportRequested && segmentExportRows.length > 0) {
      setSegmentExportRows([])
    }
  }, [hasAccessToMotivations, isExportRequested, rows, segmentConfigurations, segmentExportRows.length, singleSegmentIndex])

  return { segmentExportRows }
}

/**
 * Market Explorer Games Data Export
 * @param segmentConfigurations
 * @param rows
 * @param singleSegmentIndex
 * @param isExportRequested
 * @returns
 */
export const useExportDataMarketExplorerGames = (
  segmentConfigurations: MarketExplorerSegmentConfiguration[],
  rows: MarketExplorerGamesDataTableRow[],
  singleSegmentIndex: number | undefined,
  isExportRequested: boolean
) => {
  const [segmentExportRows, setSegmentExportRows] = useState<ExportRow[][]>([])
  const { map: conventionalSubgenresMap } = useConventionalSubgenresMap()
  const hasAccessToDemographicsData = useDemographicsDataAccessCheck()
  const hasAccessToMotivations = useMotivationsAccessCheck()

  useEffect(() => {
    if (isExportRequested) {
      const getDataRows = (segmentConfiguration: MarketExplorerSegmentConfiguration, segmentRows: MarketExplorerGamesDataTableRow[]) => {
        const exportRows: {}[] = []
        const maxRank = 1000

        // sort games by name
        let sortedRows = segmentRows.sort((a, b) => {
          return a.game.resolvedName.localeCompare(b.game.resolvedName)
        })

        const revenueDataId = `${t('common:monthly_performance_text')} | ${t('common:revenue_text')}`
        const downloadsDataId = `${t('common:monthly_performance_text')} | ${t('common:downloads_text')}`
        const revDlRatioDataId = `${t('common:monthly_performance_text')} | ${t('common:revenue_downloads_ratio_shorthand')}`
        const revenueDataAvailable = revenueIsAvailableForMarket(segmentConfiguration.marketIso)
        const revenueHalfYearDataId = t('common:half_year_performance_text') + ' | ' + t('common:revenue_text')
        const downloadsHalfYearDataId = t('common:half_year_performance_text') + ' | ' + t('common:downloads_text')
        const revDlRatioHalfYearDataId = t('common:half_year_performance_text') + ' | ' + t('common:revenue_downloads_ratio_shorthand')
        const revenueYearDataId = t('common:annual_performance_text') + ' | ' + t('common:revenue_text')
        const downloadsYearDataId = t('common:annual_performance_text') + ' | ' + t('common:downloads_text')
        const revDlRatioYearDataId = t('common:annual_performance_text') + ' | ' + t('common:revenue_downloads_ratio_shorthand')
        const demographicsMaleDataId = t('common:gender') + ' | ' + t('common:male')
        const demographicsFemaleDataId = t('common:gender') + ' | ' + t('common:female')
        const demographicsAge16_24DataId = t('common:age') + ' | ' + t('common:age16_24')
        const demographicsAge25_44DataId = t('common:age') + ' | ' + t('common:age25_44')
        const demographicsAge45DataId = t('common:age') + ' | ' + t('common:age45_plus')
        const motivationTypes = motivationService.getMotivationTypes()
        const keyMotivations = motivationService.getKeyMotivations()

        sortedRows.forEach((row) => {
          const subgenreName = row.game.conventionalSubgenre
          const genreName = conventionalSubgenresMap[row.game.conventionalSubgenreId]?.genre.name
          const categoryName = conventionalSubgenresMap[row.game.conventionalSubgenreId]?.category.name
          const game = row.game

          const demographicsDataAvailable =
            utilsService.demographicsIsAvailableForMarket(segmentConfiguration.marketIso) &&
            game.getDemographicsPercentForIdAndMarket('demographicsMale', segmentConfiguration.marketIso) > 0

          const motivationsData = row.segmentData?.data?.motivationData?.motivations?.find((motivation) => motivation.gameId === game.id)
          const motivationArchetypesData = motivationsData?.motivationSegmentValues

          let newDataRow = {}

          newDataRow = {
            ...newDataRow,
            ...{
              'App ID': game.appId,
              [t('common:game')]: game.resolvedName,
              [t('common:publisher')]: game.artist,
              [t('common:conventional_subgenre')]: subgenreName,
              [t('common:conventional_genre')]: genreName,
              [t('common:conventional_category')]: categoryName,
              [t('common:game_power_score')]:
                game.getPowerScoreForMarket(segmentConfiguration.marketIso) > 0
                  ? Math.round(game.getPowerScoreForMarket(segmentConfiguration.marketIso))
                  : t('common:not_available_shorthand'),
              [t('common:sensomotoric')]: game.getSensomotoric() ? Math.round(game.sensomotoric * 100) / 100 : t('common:not_available_shorthand'),
              [t('common:cognitive')]: game.getCognitive() ? Math.round(game.cognitive * 100) / 100 : t('common:not_available_shorthand'),
              [t('common:sustained_grossing_rank')]:
                game.getSustainedGrossingRankForMarket(segmentConfiguration.marketIso) <= maxRank
                  ? game.getSustainedGrossingRankForMarket(segmentConfiguration.marketIso)
                  : `${maxRank}+`,
              [t('common:sustained_download_rank')]:
                game.getSustainedDownloadRankForMarket(segmentConfiguration.marketIso) <= maxRank
                  ? game.getSustainedDownloadRankForMarket(segmentConfiguration.marketIso)
                  : `${maxRank}+`,
              [t('common:days_since_release_text')]: game.getDaysSinceRelease() <= maxRank ? game.getDaysSinceRelease() : `${maxRank}+`,
              [revenueDataId]:
                revenueDataAvailable && (game.getRevenuePastForMarket(EstimateTypes.REVENUE_30_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getRevenuePastForMarket(EstimateTypes.REVENUE_30_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [downloadsDataId]:
                revenueDataAvailable && (game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_30_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_30_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [revDlRatioDataId]:
                revenueDataAvailable &&
                (game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_30_DAY, segmentConfiguration.marketIso) as number) >
                  0
                  ? game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_30_DAY, segmentConfiguration.marketIso)
                  : t('common:not_available_shorthand'),
              [revenueHalfYearDataId]:
                revenueDataAvailable && (game.getRevenuePastForMarket(EstimateTypes.REVENUE_180_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getRevenuePastForMarket(EstimateTypes.REVENUE_180_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [downloadsHalfYearDataId]:
                revenueDataAvailable && (game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_180_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_180_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [revDlRatioHalfYearDataId]:
                revenueDataAvailable &&
                (game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_180_DAY, segmentConfiguration.marketIso) as number) >
                  0
                  ? game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_180_DAY, segmentConfiguration.marketIso)
                  : t('common:not_available_shorthand'),
              [revenueYearDataId]:
                revenueDataAvailable && (game.getRevenuePastForMarket(EstimateTypes.REVENUE_360_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getRevenuePastForMarket(EstimateTypes.REVENUE_360_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [downloadsYearDataId]:
                revenueDataAvailable && (game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_360_DAY, segmentConfiguration.marketIso) as number) > 0
                  ? game.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_360_DAY, segmentConfiguration.marketIso, false, true)
                  : t('common:not_available_shorthand'),
              [revDlRatioYearDataId]:
                revenueDataAvailable &&
                (game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_360_DAY, segmentConfiguration.marketIso) as number) >
                  0
                  ? game.getRevenueDownloadsRatioForMarket(RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_360_DAY, segmentConfiguration.marketIso)
                  : t('common:not_available_shorthand'),
            },
          }

          if (hasAccessToDemographicsData) {
            newDataRow = {
              ...newDataRow,
              ...{
                [demographicsMaleDataId]: demographicsDataAvailable
                  ? game.getDemographicsPercentForIdAndMarket(DemographicTypes.demographicsMale, segmentConfiguration.marketIso) + '%'
                  : t('common:not_available_shorthand'),
                [demographicsFemaleDataId]: demographicsDataAvailable
                  ? game.getDemographicsPercentForIdAndMarket(DemographicTypes.demographicsFemale, segmentConfiguration.marketIso) + '%'
                  : t('common:not_available_shorthand'),
                [demographicsAge16_24DataId]: demographicsDataAvailable
                  ? game.getDemographicsPercentForIdAndMarket(DemographicTypes.demographicsAge16_24, segmentConfiguration.marketIso) + '%'
                  : t('common:not_available_shorthand'),
                [demographicsAge25_44DataId]: demographicsDataAvailable
                  ? game.getDemographicsPercentForIdAndMarket(DemographicTypes.demographicsAge25_44, segmentConfiguration.marketIso) + '%'
                  : t('common:not_available_shorthand'),
                [demographicsAge45DataId]: demographicsDataAvailable
                  ? game.getDemographicsPercentForIdAndMarket(DemographicTypes.demographicsAge45, segmentConfiguration.marketIso) + '%'
                  : t('common:not_available_shorthand'),
              },
            }
          }

          if (hasAccessToMotivations) {
            motivationTypes.forEach((motivationType) => {
              newDataRow = {
                ...newDataRow,
                ...{
                  [`${t('motivations:motivations')} | ${motivationService.getSegmentName(motivationType)}`]: motivationsData
                    ? motivationsData.motivationValues[motivationType]
                    : t('common:not_available_shorthand'),
                },
              }
            })

            if (motivationArchetypesData) {
              keyMotivations.forEach((keyMotivation) => {
                newDataRow = {
                  ...newDataRow,
                  ...{
                    [`${t('motivations:player_archetypes')} | ${motivationService.getSegmentName(keyMotivation)}`]: motivationsData
                      ? `${Math.round(motivationArchetypesData[keyMotivation] * 1000) / 10}%`
                      : t('common:not_available_shorthand'),
                  },
                }
              })
            }
          }

          exportRows.push(newDataRow)
        })

        return exportRows
      }

      const newSegmenetExportRows: ExportRow[][] = []

      if (singleSegmentIndex === undefined) {
        // All segments
        segmentConfigurations.forEach((segmentConfiguration, index) => {
          const exportRows = getDataRows(
            segmentConfiguration,
            rows.filter((row) => row.segmentIndexes && row.segmentIndexes.includes(index))
          )

          newSegmenetExportRows.push(exportRows)
        })
      } else {
        // Single segment
        const exportRows = getDataRows(
          segmentConfigurations[0],
          rows.filter((row) => row.segmentIndexes && row.segmentIndexes.includes(singleSegmentIndex))
        )

        newSegmenetExportRows.push(exportRows)
      }

      setSegmentExportRows(newSegmenetExportRows)
    }

    if (!isExportRequested && segmentExportRows.length > 0) {
      setSegmentExportRows([])
    }
  }, [
    isExportRequested,
    segmentConfigurations,
    rows,
    hasAccessToDemographicsData,
    hasAccessToMotivations,
    segmentExportRows.length,
    conventionalSubgenresMap,
    singleSegmentIndex,
  ])

  return { segmentExportRows }
}

export const useExportDataFeatureFacts = (
  feature: DetailedFeature | undefined,
  choice: DetailedFeatureChoice | undefined,
  data: FeatureFactsResponse | undefined,
  isExportRequested: Boolean
) => {
  const [exportRows, setExportRows] = useState<ExportRow[]>([])
  const dateFormat = 'dd MMM yyyy'

  useEffect(() => {
    if (isExportRequested && data && feature && choice) {
      const newExportRows: {}[] = []

      // Sort by date as the data is not sorted internally
      const in20Percent: TrendingValue[] = data.graph.in20Percent.slice().sort((a, b) => (a.ts > b.ts ? 1 : -1))
      const out20Percent: TrendingValue[] = data.graph.out20Percent.slice().sort((a, b) => (a.ts > b.ts ? 1 : -1))

      in20Percent.forEach((item: TrendingValue, index) => {
        const outItem: TrendingValue = out20Percent[index]

        let newDataRow = {
          [t('common:feature')]: `${feature.name}: ${choice.choice}`,
          [t('common:date')]: format(item.ts, dateFormat),
          [t('common:top_20_popularity_percent_short')]: `${Math.round(item.value)}%`,
          [t('common:top_20_popularity_out_percent_short')]: `${Math.round(outItem.value)}%`,
        }

        newExportRows.push(newDataRow)
      })

      setExportRows(newExportRows)
    }

    if (!isExportRequested && exportRows.length > 0) {
      setExportRows([])
    }
  }, [isExportRequested, exportRows.length, data, feature, choice])

  return { exportRows }
}

const formatDateByGranularity = (date: Date | number, granularity: GranularityValue) => {
  switch (granularity) {
    case GranularityValue.Day:
      return format(date, 'yyyy/MM/dd')
    case GranularityValue.Week:
      return format(date, 'RRRR/I')
    case GranularityValue.Month:
      return format(date, 'yyyy/MM')
  }
}

const adjustDateByGranularity = (date: Date | number, granularity: GranularityValue) => {
  switch (granularity) {
    case GranularityValue.Day:
      return startOfDay(date)
    case GranularityValue.Week:
      return startOfWeek(date)
    case GranularityValue.Month:
      return startOfMonth(date)
  }
}
const isInSameGranularityPeriod = (granularity: GranularityValue) => (date1: Date | number, date2: Date | number) => {
  switch (granularity) {
    case GranularityValue.Day:
      return isSameDay(date1, date2)
    case GranularityValue.Week:
      return isSameWeek(date1, date2)
    case GranularityValue.Month:
      return isSameMonth(date1, date2)
  }
}
