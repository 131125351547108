import { t } from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PlayArrow } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material'

import { FtueVideo } from '../../../../api/core'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import MultiSlider from '../../../../components/MultiSlider/MultiSlider'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { Game } from '../../../game'
import { useIsGameOpenForEveryone } from '../../../settings'
import FtueVideoFullscreenModal from '../FtueVideoFullscreenModal/FtueVideoFullscreenModal'
import { SelectedChapterTag } from '../GameChapterList/GameChapterList'
import './FtueVideoPreview.scss'

/**
 * VideoThumbnails show video thumbnails according to videos length
 */
interface VideoThumbnailsProps {
  videos?: FtueVideo[]
  onVideoSelected: (video: FtueVideo) => void
}
const VideoThumbnails: React.FC<VideoThumbnailsProps> = ({ videos, onVideoSelected }) => {
  if (!videos) return null

  const videoStyleProps = (video: FtueVideo) => {
    const height: number = 199
    const thumbnail = video.thumbnails[2]
    const width: number | string = thumbnail ? (thumbnail.width * height) / thumbnail.height : '100%'
    const backgroundImage: string = 'url(' + thumbnail?.link + ')'
    return {
      width,
      height,
      backgroundImage,
    }
  }

  switch (videos.length) {
    case 0:
      return <Typography paragraph>{t('ftue-videos:no_ftue_videos_found')}</Typography>

    case 1:
      return (
        <Box className="VimeoPreview" sx={{ ...videoStyleProps(videos[0]) }}>
          <Button color="primary" variant="contained" startIcon={<PlayArrow />} onClick={() => onVideoSelected(videos[0])} />
        </Box>
      )

    default:
      return (
        <MultiSlider padding={60} count={1} showIndex>
          {videos.map((video) => (
            <Box key={video.id} className="VimeoPreview" sx={{ ...videoStyleProps(video) }}>
              <Button color="primary" variant="contained" startIcon={<PlayArrow />} onClick={() => onVideoSelected(video)} />
            </Box>
          ))}
        </MultiSlider>
      )
  }
}

interface FtueVideoPreviewProps {
  game: Game
  isLoading?: boolean
  videos?: FtueVideo[]
  showOnlyLatest?: boolean
  selectedChapterTag?: SelectedChapterTag
  onChapterTagClick?: ({ chapterId, tagId }: SelectedChapterTag) => void
}

/**
 * @param isLoading shows progressing if true
 * @param videos from which the first one is picked
 */
const FtueVideoPreview: React.FC<FtueVideoPreviewProps> = ({ game, isLoading, videos, showOnlyLatest = false, selectedChapterTag, onChapterTagClick }) => {
  const { t } = useTranslation()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const isFTUEVideosUnlocked = useRoleCheck(RoleEnum.ftue_videos) || isGameOpenForEveryone
  const [selectedVideo, setSelectedVideo] = useState<FtueVideo | undefined>()

  return isFTUEVideosUnlocked ? (
    <Card className="Card Card--full-height">
      <CardContent>
        <h3>{t('ftue-videos:ftue')}</h3>
        <Box className="FtueVideoPreview">
          {isLoading ? (
            <CircularProgress sx={{ mt: 10 }} />
          ) : (
            <VideoThumbnails videos={showOnlyLatest && videos ? [videos[0]] : videos} onVideoSelected={setSelectedVideo} />
          )}
        </Box>
      </CardContent>
      {selectedVideo && (
        <FtueVideoFullscreenModal
          game={game}
          onClose={() => setSelectedVideo(undefined)}
          position={0}
          video={selectedVideo}
          selectedChapterTag={selectedChapterTag}
          onChapterTagClick={onChapterTagClick}
        />
      )}
    </Card>
  ) : (
    <LockedFeature.Card lockedFeatureId={LockedFeatureId.FtueVideos} hideImage={true} />
  )
}

export default FtueVideoPreview
