import React from 'react'

import { CardHeader } from '@mui/material'

const LiveEventsEventsSelector: React.FC = () => {
  return (
    <>
      <CardHeader title="Select events" subheader="View events from selected filters"></CardHeader>
    </>
  )
}

export default LiveEventsEventsSelector
