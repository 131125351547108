import { subDays } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetExplorerDataWithFeaturesQuery, useGetExplorerDataWithGamesQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import utilsService from '../../../services/UtilsService'
import { VersionInfo } from '../../../types/VersionInfo'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { Estimates, EstimateTypes } from '../../game/types/Game'
import { DataFilters } from '../types/MarketExplorerDataFilters'
import {
  DemographicsFilterField,
  MarketExplorerSegmentConfiguration,
  PlayerArchetypesFilterField,
  RanksFilterField,
} from '../types/MarketExplorerSegmentConfiguration'
import {
  MarketExplorerSegmentFilterConfig,
  MarketExplorerSegmentFilterGroup,
  MarketExplorerSegmentFilterValueType,
} from '../types/MarketExplorerSegmentFilterConfig'
import { MotivationType } from '../types/MotivationType'
import { getRanksDemographicsAndMotivationFilterValues } from '../util/utils'

export const useMotivationFilterConfig = () => {
  const defaultMin = 1
  const defaultMax = 5
  const defaultSteps = 0.1
  const { marketExplorerSegmentMotivationTypes } = useMarketExplorerSegmentFilterGroups()

  return useMemo(() => {
    return marketExplorerSegmentMotivationTypes.map((motivationTypeGroup) => {
      return {
        label: motivationTypeGroup.label,
        filters: motivationTypeGroup.types.map((motivationType) => {
          // strip the group name from the translation
          const motivationTypeLabelParts = languageService.getTranslation('motivations', motivationType.type).match(/.*( - |–|:|：)(.*)/)

          return {
            id: motivationType.id,
            label: motivationTypeLabelParts?.length === 3 ? motivationTypeLabelParts[2].trim() : motivationTypeLabelParts?.input,
            min: defaultMin,
            max: defaultMax,
            steps: defaultSteps,
            type: MarketExplorerSegmentFilterValueType.Number,
          }
        }),
      }
    }) as MarketExplorerSegmentFilterGroup<MotivationType>[]
  }, [marketExplorerSegmentMotivationTypes])
}

// handles fetching of segment data
export const useSegmentData = (segment: MarketExplorerSegmentConfiguration) => {
  const featureChoiceCount = Object.keys(segment.featureChoices || {}).length
  const { marketExplorerSegmentRanksFilterGroups, marketExplorerSegmentDemographicsFilterGroups, marketExplorerSegmentPlayerArchetypesFilterGroups } =
    useMarketExplorerSegmentFilterGroups()
  const motivationFilterConfig = useMotivationFilterConfig()
  const userLanguage = useCurrentUserLanguage()

  // prepare data filter values for query
  const dataFilters: DataFilters = useMemo(() => {
    const filters = Object.entries({ ...segment.filters.ranks, ...segment.filters.demographics }).reduce((acc, [key, value]) => {
      if (value) {
        const filterConfig = getFilterConfig([...marketExplorerSegmentRanksFilterGroups, ...marketExplorerSegmentDemographicsFilterGroups], key)
        const { minValue, maxValue } = getRanksDemographicsAndMotivationFilterValues({ filterConfig, minValue: value[0], maxValue: value[1] })

        // transform "days since released" date range values to timestamps reflected from current time
        if (key === RanksFilterField.DaysSinceRelease) {
          const currentTime = new Date().setHours(23, 59, 59, 999)
          const minDaysSinceNowTimestamp = minValue !== undefined ? subDays(currentTime, minValue).getTime() : undefined
          const maxDaysSinceNowTimestamp = maxValue !== undefined ? subDays(currentTime, maxValue).getTime() : undefined

          acc[key] = { max: minDaysSinceNowTimestamp, min: maxDaysSinceNowTimestamp }
        } else {
          acc[key] = { min: minValue, max: maxValue }
        }
      }

      return acc
    }, {} as any)

    return {
      ...filters,
      motivationDataFilters: Object.entries({ ...segment.filters.motivations }).reduce((acc, [key, value]) => {
        if (value) {
          const filterConfig = getFilterConfig(motivationFilterConfig, key)
          const { minValue, maxValue } = getRanksDemographicsAndMotivationFilterValues({ filterConfig, minValue: value[0], maxValue: value[1] })
          acc[key] = { min: minValue, max: maxValue }
        }

        return acc
      }, {} as any),
      archetypeFilters: Object.entries({ ...segment.filters.archetypes }).reduce((acc, [key, value]) => {
        if (value) {
          const filterConfig = getFilterConfig(marketExplorerSegmentPlayerArchetypesFilterGroups, key)
          const minValue = value[0] === filterConfig.min ? undefined : value[0]
          const maxValue = value[1] === filterConfig.max ? undefined : value[1]
          acc[key] = { min: minValue, max: maxValue }
        }

        return acc
      }, {} as any),
    }
  }, [
    marketExplorerSegmentDemographicsFilterGroups,
    marketExplorerSegmentPlayerArchetypesFilterGroups,
    marketExplorerSegmentRanksFilterGroups,
    motivationFilterConfig,
    segment.filters.archetypes,
    segment.filters.demographics,
    segment.filters.motivations,
    segment.filters.ranks,
  ])

  // fetch data with games if no feature choices have been selected
  const explorerDataWithGames = useGetExplorerDataWithGamesQuery(
    {
      marketIso: segment.marketIso,
      conventionalSubgenreIds: segment.subgenres,
      dataFilters,
      ownGames: segment.ownGames,
      gameIds: segment.gameIds,
      userLanguage,
    },
    { skip: featureChoiceCount > 0 }
  )

  // fetch data with feature choices if they have been selected
  const explorerDataWithFeatures = useGetExplorerDataWithFeaturesQuery(
    {
      marketIso: segment.marketIso,
      conventionalSubgenreIds: segment.subgenres,
      dataFilters,
      choiceLegacyIds: Object.values(segment.featureChoices || {}).flatMap((featureChoices) => featureChoices),
      ownGames: segment.ownGames,
      userLanguage,
    },
    { skip: featureChoiceCount === 0 }
  )

  const segmentData = featureChoiceCount > 0 ? explorerDataWithFeatures : explorerDataWithGames

  const estimates = useMemo(() => {
    // aggregate estimate data for all games in segment
    return segmentData.data?.games.reduce((acc, game) => {
      Object.values(EstimateTypes).forEach((estimateType) => {
        acc[estimateType] = (acc[estimateType] || 0) + (game?.revenueAndDownloadAggregatesMap?.[segment.marketIso]?.[estimateType] || 0)
      })

      return acc
    }, {} as Estimates)
  }, [segment.marketIso, segmentData.data?.games])

  const revenueTrend180d = useMemo(() => {
    const previousRevenue180d = (estimates?.revenue360d || 0) - (estimates?.revenue180d || 0)

    return ((estimates?.revenue180d || 0) - previousRevenue180d) / previousRevenue180d
  }, [estimates?.revenue180d, estimates?.revenue360d])

  const downloadsTrend180d = useMemo(() => {
    const previousDownloads180d = (estimates?.downloads360d || 0) - (estimates?.downloads180d || 0)

    return ((estimates?.downloads180d || 0) - previousDownloads180d) / previousDownloads180d
  }, [estimates?.downloads180d, estimates?.downloads360d])

  return {
    segmentData,
    complementaryData: {
      estimates,
      revenueTrend180d,
      downloadsTrend180d,
    },
  }
}

export const useMarketExplorerSegmentFilterGroups = () => {
  const { t } = useTranslation()
  return useMemo(() => {
    const marketExplorerSegmentRanksFilterGroups: MarketExplorerSegmentFilterGroup<RanksFilterField>[] = [
      {
        label: t('common:ranks_text'),
        filters: [
          {
            id: RanksFilterField.SRanks,
            label: t('common:sustained_grossing_rank'),
            min: 1,
            max: 1500,
            steps: [1, 50, 100, 200, 500, 1000, 1500],
            type: MarketExplorerSegmentFilterValueType.Number,
            maxLabel: '+1500',
          },
          {
            id: RanksFilterField.SDRanks,
            label: t('common:sustained_download_rank'),
            min: 1,
            max: 1500,
            steps: [1, 50, 100, 200, 500, 1000, 1500],
            type: MarketExplorerSegmentFilterValueType.Number,
            maxLabel: '+1500',
          },
          {
            id: RanksFilterField.DaysSinceRelease,
            label: t('common:days_since_release'),
            min: 0,
            max: 1735,
            steps: 1,
            type: MarketExplorerSegmentFilterValueType.Number,
            maxLabel: '+1735',
          },
        ],
      },
    ]

    const marketExplorerSegmentDemographicsFilterGroups: MarketExplorerSegmentFilterGroup<DemographicsFilterField>[] = [
      {
        label: t('common:age'),
        filters: [
          {
            id: DemographicsFilterField.DemographicsAge16_24,
            label: t('common:age16_24'),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: DemographicsFilterField.DemographicsAge25_44,
            label: t('common:age25_44'),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: DemographicsFilterField.DemographicsAge45,
            label: t('common:age45_plus'),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
        ],
      },
      {
        label: t('common:gender'),
        filters: [
          {
            id: DemographicsFilterField.DemographicsMale,
            label: t('common:male'),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: DemographicsFilterField.DemographicsFemale,
            label: t('common:female'),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
        ],
      },
    ]

    const marketExplorerSegmentPlayerArchetypesFilterGroups: MarketExplorerSegmentFilterGroup<PlayerArchetypesFilterField>[] = [
      {
        label: '',
        filters: [
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeExpressionist,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeExpressionist),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeThinker,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeThinker),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeTreasureHunter,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeTreasureHunter),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeStrategist,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeStrategist),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
        ],
      },
      {
        label: '',
        filters: [
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeSkillMaster,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeSkillMaster),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeThrillSeeker,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeThrillSeeker),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeKingOfTheHill,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeKingOfTheHill),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
          {
            id: PlayerArchetypesFilterField.MotivationPlayerArchetypeNetworker,
            label: languageService.getTranslation('motivations', PlayerArchetypesFilterField.MotivationPlayerArchetypeNetworker),
            min: 0,
            max: 1,
            steps: 0.01,
            type: MarketExplorerSegmentFilterValueType.Percent,
          },
        ],
      },
    ]

    const marketExplorerSegmentMotivationTypes = [
      {
        label: t('motivations:motivations_escapism'),
        types: [
          { type: MotivationType.EscapismExcitementAndThrill, id: MotivationType.EscapismExcitementAndThrill },
          { type: MotivationType.EscapismThinkingAndSolving, id: MotivationType.EscapismThinkingAndSolving },
        ],
      },
      {
        label: t('motivations:motivations_exploration'),
        types: [
          { type: MotivationType.ExplorationCollectingTreasure, id: MotivationType.ExplorationCollectingTreasure },
          { type: MotivationType.ExplorationDiscoveringNewWorlds, id: MotivationType.ExplorationDiscoveringNewWorlds },
        ],
      },
      {
        label: t('motivations:motivations_expression'),
        types: [
          { type: MotivationType.ExpressionCustomizationAndDecoration, id: MotivationType.ExpressionCustomizationAndDecoration },
          { type: MotivationType.ExpressionRolePlayingAndEmotions, id: MotivationType.ExpressionRolePlayingAndEmotions },
        ],
      },
      {
        label: t('motivations:motivations_management'),
        types: [
          { type: MotivationType.ManagementResourceOptimization, id: MotivationType.ManagementResourceOptimization },
          { type: MotivationType.ManagementStrategicPlanning, id: MotivationType.ManagementStrategicPlanning },
        ],
      },
      {
        label: t('motivations:motivations_mastery'),
        types: [
          { type: MotivationType.MasteryCompletingMilestones, id: MotivationType.MasteryCompletingMilestones },
          { type: MotivationType.MasteryImprovingSkills, id: MotivationType.MasteryImprovingSkills },
        ],
      },
      {
        label: t('motivations:motivations_social'),
        types: [
          { type: MotivationType.SocialCompetingAgainstOthers, id: MotivationType.SocialCompetingAgainstOthers },
          { type: MotivationType.SocialWorkingWithOthers, id: MotivationType.SocialWorkingWithOthers },
        ],
      },
    ]

    return {
      marketExplorerSegmentRanksFilterGroups,
      marketExplorerSegmentDemographicsFilterGroups,
      marketExplorerSegmentMotivationTypes,
      marketExplorerSegmentPlayerArchetypesFilterGroups,
    }
  }, [t])
}

export const useDaysSinceRelease = (gameVersionInfo: VersionInfo | undefined, marketIso: string) => {
  const now = Date.now()

  const isFutureReleaseDate = (date: number) => date > now

  const getDaysDifference = (date: number): number => {
    const difference = isFutureReleaseDate(date)
      ? `- ${utilsService.getDayDifferenceFromTwoDates(now, date)}`
      : utilsService.getDayDifferenceFromTwoDates(date, now)

    return typeof difference === 'string' ? Number(difference) : difference
  }

  return gameVersionInfo ? getDaysDifference(gameVersionInfo.releaseDate) : 0
}

const getFilterConfig = <T extends {}>(filterGroups: MarketExplorerSegmentFilterGroup<T>[], filterId: T) => {
  return filterGroups.reduce((acc, filterGroup) => {
    const found = filterGroup.filters.find((filter) => filter.id === filterId)
    return found ? found : acc
  }, {} as MarketExplorerSegmentFilterConfig<T>)
}
