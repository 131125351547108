import { FC, ReactNode } from 'react'

import { useDaysSinceRelease } from '../../features/market-explorer/hooks/marketExplorerHooks'
import { VersionInfo } from '../../types/VersionInfo'

export type RankIndicatorProps = {
  value: number
  maxValue?: number
  emptyValue?: ReactNode
  children?: ReactNode
  hasEmptyValue?: boolean
  gameVersion?: string
  gameVersionInfo?: VersionInfo
  currentMarketIso?: string
}

const defaultEmptyValue = ''

export const RankIndicator: FC<RankIndicatorProps> = ({
  value,
  maxValue,
  emptyValue = defaultEmptyValue,
  hasEmptyValue = false,
  gameVersionInfo,
  currentMarketIso = 'us',
}) => {
  let displayValue

  const daysRelease = useDaysSinceRelease(gameVersionInfo, currentMarketIso)

  if (isNaN(value) || !isFinite(value) || (value === 0 && !hasEmptyValue)) {
    displayValue = emptyValue
  } else {
    const roundedValue = Math.round(value)
    displayValue = !isNaN(maxValue as number) ? (value > (maxValue as number) ? `${maxValue}+` : roundedValue) : roundedValue
  }

  return <span>{gameVersionInfo ? (daysRelease && maxValue ? (daysRelease > maxValue ? `${maxValue}+` : daysRelease) : `${maxValue}+`) : displayValue}</span>
}
