import { useInternalAccessCheck } from '../../account/hooks/roleHooks'
import { TLiveEventsCommonFilters, LiveEventsCommonFiltersData } from '../types/LiveEventsCommonFilters'

export const useLiveEventCommonFilterCounts = ({
  filters,
  data,
  hdGameSelected,
}: {
  filters: TLiveEventsCommonFilters
  data: LiveEventsCommonFiltersData
  hdGameSelected: boolean
}) => {
  const internalUser = useInternalAccessCheck() // NOTE: Remove Internal role check when we want to release LET Price points / motivations

  if (internalUser) {
    const activeCount =
      filters.liveEventTags.length +
      filters.liveEventSecondaryTags.length +
      Object.keys(filters.additionalDatas).length +
      Object.keys(filters.liveEventDurations).length +
      Object.keys(filters.liveEventAppearances).length +
      Object.keys(filters.motivations).length +
      Object.keys(filters.archetypes).length +
      (hdGameSelected ? Object.keys(filters.liveEventPricePoints).length : 0)
    const maxCount =
      data.liveEventTags.length +
      data.liveEventSecondaryTags.length +
      data.additionalDatas.length +
      data.liveEventAppearances.length +
      data.liveEventDurations.length +
      data.motivations.length +
      data.archetypes.length +
      (hdGameSelected ? data.liveEventPricePoints.length : 0)
    return {
      activeCount,
      maxCount,
    }
  }

  const activeCount =
    filters.liveEventTags.length +
    filters.liveEventSecondaryTags.length +
    Object.keys(filters.additionalDatas).length +
    Object.keys(filters.liveEventDurations).length +
    Object.keys(filters.liveEventAppearances).length

  const maxCount =
    data.liveEventTags.length +
    data.liveEventSecondaryTags.length +
    data.additionalDatas.length +
    data.liveEventAppearances.length +
    data.liveEventDurations.length

  return {
    activeCount,
    maxCount,
  }
}
