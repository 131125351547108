import React, { useEffect, useMemo, useState } from 'react'

import { Close } from '@mui/icons-material'
import { Card, Dialog, Grid, IconButton } from '@mui/material'
import Player, { VolumeChangeEvent } from '@vimeo/player'

// Import proper typings from @types/vimeo__player
import { FtueVideo } from '../../../../api/core'
import type { Game } from '../../../game'
import { Size, useDomSize } from '../../hooks/useDomSize'
import type { SelectedChapterTag } from '../GameChapterList/GameChapterList'
import GameChapterList from '../GameChapterList/GameChapterList'
import './FtueVideoFullscreenModal.scss'

interface FtueVideoFullscreenModalProps {
  autoPlay?: boolean
  game?: Game
  onClose: () => void
  position: number
  video: FtueVideo
  title?: string
  volume?: number
  onVolumeChange?: (volume: number) => void
  onTimeChange?: (time: number) => void
  isMuted?: boolean
  handleVolumeMuted?: (isMuted: boolean) => void
  selectedChapterTag?: SelectedChapterTag
  onChapterTagClick?: ({ chapterId, tagId }: SelectedChapterTag) => void
}

const FtueVideoFullscreenModal: React.FC<FtueVideoFullscreenModalProps> = ({
  autoPlay,
  game,
  onClose,
  position,
  video,
  title,
  volume,
  onVolumeChange,
  onTimeChange,
  isMuted,
  handleVolumeMuted,
  selectedChapterTag,
  onChapterTagClick,
}) => {
  const [playerSize, setPlayerSize] = useState<Size>()
  const [currentPosition, setCurrentPosition] = useState(0)
  const [mediaContainer, setMediaContainer] = useState<HTMLDivElement | null>(null)
  const [playerElement, setPlayerElement] = useState<HTMLDivElement | null>(null)
  const [initialized, setInitialized] = useState(false)

  const debounce = (func: Function, delay: number) => {
    let timer: NodeJS.Timeout
    return (...args: any[]) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), delay)
    }
  }

  const player = useMemo(() => {
    if (!playerElement || !playerSize || initialized) return

    const playerInstance = new Player(playerElement, {
      autoplay: autoPlay,
      id: parseInt(video.vimeoId, 10),
      ...playerSize,
    })

    setInitialized(true)

    if (position) {
      playerInstance.setCurrentTime(position)
      setCurrentPosition(position)
    }

    playerInstance.on('timeupdate', ({ seconds }: { seconds: number }) => {
      setCurrentPosition(seconds)
      onTimeChange && onTimeChange(seconds)
    })

    playerInstance.on(
      'volumechange',
      debounce(async (event: VolumeChangeEvent) => {
        if (onVolumeChange) onVolumeChange(event.volume)
        const isPlayerMuted = await playerInstance.getMuted()
        if (handleVolumeMuted) handleVolumeMuted(isPlayerMuted)
      }, 200)
    )

    playerInstance.setVolume(volume || 0.5)
    playerInstance.setMuted(isMuted || false)

    return playerInstance
  }, [playerElement, playerSize, initialized, autoPlay, video.vimeoId, position, volume, isMuted, onTimeChange, onVolumeChange, handleVolumeMuted])

  useEffect(() => {
    return () => {
      player?.destroy()
    }
  }, [player])

  useDomSize(mediaContainer, 0, (newSize) => {
    if (!playerSize || playerSize.width !== newSize.width || playerSize.height !== newSize.height) {
      setPlayerSize(newSize)
    }
  })

  return (
    <Dialog fullScreen open onClose={onClose} closeAfterTransition className="FtueVideoFullscreenModal">
      <Grid container justifyContent="space-between">
        <Grid item sm={9} textAlign="center" ref={setMediaContainer}>
          <div ref={setPlayerElement} style={playerSize} />
        </Grid>
        <Grid item sm={3} className="sideBar">
          <IconButton className="close" onClick={onClose}>
            <Close />
          </IconButton>
          <Card className="chapterList">
            <GameChapterList
              title={title}
              game={game}
              video={video}
              setPosition={(seconds) => player?.setCurrentTime(seconds)}
              currentPosition={currentPosition}
              selectedChapterTag={selectedChapterTag}
              onChapterTagClick={onChapterTagClick}
            />
          </Card>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default FtueVideoFullscreenModal
