import { GameNameStatsType } from '../types'

export type Translations = {
  [type in GameNameStatsType]: { header: string; title: string; label: string }
}

export const translations: Translations = {
  wordFrequency: {
    header: 'visuals-explorer:word_frequency_title',
    label: 'visuals-explorer:word',
    title: 'visuals-explorer:games_with_word',
  },
  numberOfWords: {
    header: 'visuals-explorer:number_of_words',
    label: 'visuals-explorer:number_of_words',
    title: 'visuals-explorer:games_with_word_count',
  },
  titleLength: {
    header: 'visuals-explorer:length_of_words_title',
    label: 'visuals-explorer:length',
    title: 'visuals-explorer:games_with_title_length',
  },
}
