import { LiveEventTrackerSearchMethod } from './LiveEventTrackerSearchMethod'

export enum LiveEventTrackerTab {
  Feed = 'feed',
  Games = 'games',
  Events = 'events',
  Statistics = 'statistics',
}

export type LiveEventTrackerTabVisibility = {
  [key in LiveEventTrackerTab]: {
    [key in LiveEventTrackerSearchMethod]: boolean
  }
}

export const liveEventTrackerTabVisibility: LiveEventTrackerTabVisibility = {
  [LiveEventTrackerTab.Feed]: {
    [LiveEventTrackerSearchMethod.games]: true,
    [LiveEventTrackerSearchMethod.events]: true,
  },
  [LiveEventTrackerTab.Games]: {
    [LiveEventTrackerSearchMethod.games]: true,
    [LiveEventTrackerSearchMethod.events]: false,
  },
  [LiveEventTrackerTab.Events]: {
    [LiveEventTrackerSearchMethod.games]: true,
    [LiveEventTrackerSearchMethod.events]: false,
  },
  [LiveEventTrackerTab.Statistics]: {
    [LiveEventTrackerSearchMethod.games]: true,
    [LiveEventTrackerSearchMethod.events]: false,
  },
}
