import { useGetEventsForTrackedGamesQuery } from '../../../api/core'
import { LiveEventTrackerSearchMethod } from '../types/LiveEventTrackerSearchMethod'
import { useTrackedEventsEndTimestamp } from './useTrackedEventsEndTimestamp'

type UseTrackedEventsHookParams = {
  searchMethod: LiveEventTrackerSearchMethod
  gameIds: string[]
  startTimestamp: number
  endTimestamp: number
  marketIso: string
}

export const useTrackedEvents = ({ searchMethod, gameIds, startTimestamp, endTimestamp, marketIso }: UseTrackedEventsHookParams) => {
  const endTimestampBasedOnInternalRole = useTrackedEventsEndTimestamp(endTimestamp)
  return useGetEventsForTrackedGamesQuery(
    { searchMethod, gameIds, startTimestamp, endTimestamp: endTimestampBasedOnInternalRole, marketIso },
    { skip: gameIds.length === 0 }
  )
}
