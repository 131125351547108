import { endOfYesterday } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'

import { useCurrentMarket } from '../../markets'
import { ChartDataFormat } from '../../update-history/types/types'
import { eventStatisticsPerformanceTimeStartMin, eventStatisticsTimeStartMin } from '../const/const'
import { LiveEventTrackerSearchMethod } from '../types/LiveEventTrackerSearchMethod'
import { useEventStatisticsLimitedTimeRange, useEventTypeRollingPerformance } from './liveEventsEventTypeStatistics'
import { useTrackedGamesEvents } from './useTrackedGamesEvents'

/**
 * Hook for preparing live event data for performance chart
 */
type LiveEventsEventStatisticsPerformanceChartDataHookParams = {
  eventTypeId: string
  trackedGameIds: string[]
}

const xAxisDefaults = {
  min: eventStatisticsPerformanceTimeStartMin,
  max: endOfYesterday().getTime(),
}

const yAxisDefaults = {
  min: 0,
  max: 100,
}

export const useLiveEventsEventStatisticsPerformanceChartData = ({ eventTypeId, trackedGameIds }: LiveEventsEventStatisticsPerformanceChartDataHookParams) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { currentMarketIso } = useCurrentMarket()
  const limitedTimeRange = useEventStatisticsLimitedTimeRange({ timeStart: eventStatisticsTimeStartMin, timeEnd: +endOfYesterday() })
  const trackedEvents = useTrackedGamesEvents({
    searchMethod: LiveEventTrackerSearchMethod.games,
    gameIds: trackedGameIds,
    startTimestamp: limitedTimeRange.start,
    endTimestamp: limitedTimeRange.end,
    marketIso: currentMarketIso,
  })

  const { rollingPerformance, isLoading } = useEventTypeRollingPerformance({
    interval: limitedTimeRange,
    trackingEventsByGame: trackedEvents,
    eventTypeId,
  })

  const datasets = useMemo(
    () => [
      {
        yAxisID: 'y1',
        label: t('common:revenue_text'),
        fill: false,
        spanGaps: 172800000, // 2 days
        tension: 0.1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        data: rollingPerformance.map((p) => ({ x: p.ts, y: p.revenue * 100 || 0 })),
      },
    ],
    [rollingPerformance, t, theme.palette.primary.main]
  )

  const chartAxisRange = useMemo(() => {
    return rollingPerformance.reduce(
      (acc, item) => {
        if (!acc.y.min || item.revenue < acc.y.min) {
          acc.y.min = item.revenue
        }

        if (!acc.y.max || item.revenue > acc.y.max) {
          acc.y.max = item.revenue
        }

        if (!acc.x.min || item.ts < acc.x.min) {
          acc.x.min = item.ts
        }

        if (!acc.x.max || item.ts > acc.x.max) {
          acc.x.max = item.ts
        }

        return acc
      },
      { x: {}, y: {} } as {
        x: { min?: number; max?: number }
        y: { min?: number; max?: number }
      }
    )
  }, [rollingPerformance])

  const scaleConfig = useMemo(
    () => ({
      y1: {
        dataFormat: ChartDataFormat.Percentage,
        precision: 1,
        title: t('common:revenue_text'),
        minY: chartAxisRange.y.min ? chartAxisRange.y.min * 100 : yAxisDefaults.min,
        maxY: chartAxisRange.y.max ? chartAxisRange.y.max * 100 : yAxisDefaults.max,
      },
      x: { suggestedMin: chartAxisRange.x.min || xAxisDefaults.min, suggestedMax: chartAxisRange.x.max || xAxisDefaults.max },
    }),
    [chartAxisRange.x.max, chartAxisRange.x.min, chartAxisRange.y.max, chartAxisRange.y.min, t]
  )

  return {
    datasets,
    scaleConfig,
    isLoading,
  }
}
